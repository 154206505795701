import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import SideCheckoutItem from "./SideCheckoutItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Skeleton from "@mui/material/Skeleton";
import VendorLogo from "custom/img/VendorLogo";
import { CartHeaderText } from "custom/text/shopping_page/StandardSideCartTexts";
import { LOADING_STATES } from "features/cartsSlice";

const processCheckoutItems = (vendorData, vendorCode) => {
  if (!vendorData?.items) return [];

  return vendorData.items.reduce((styleItems, checkoutItem) => {
    const { style, colorDesc, quantity, unitPrice, totalPrice, warehouse } = checkoutItem;
    
    const checkoutItemTotalPrice = calculateTotalPrice(totalPrice, unitPrice, quantity);
    const adjustedWarehouse = adjustWarehouse(warehouse, vendorCode);

    const existingStyleItemIndex = findExistingStyleItemIndex(styleItems, style, colorDesc);

    if (existingStyleItemIndex >= 0) {
      styleItems[existingStyleItemIndex] = updateExistingStyleItem(styleItems[existingStyleItemIndex], checkoutItem, adjustedWarehouse, checkoutItemTotalPrice);
    } else {
      styleItems.push(createNewStyleItem(checkoutItem, adjustedWarehouse, checkoutItemTotalPrice));
    }

    return styleItems;
  }, []);
};

const calculateTotalPrice = (totalPrice, unitPrice, quantity) => {
  let price = totalPrice || Number(unitPrice) * Number(quantity);
  return typeof price === "string" ? Number(price.replace("$", "")) : price;
};

const adjustWarehouse = (warehouse, vendorCode) => 
  vendorCode === "cm" && warehouse === "NC" ? "Indian Trail, NC" : warehouse;

const findExistingStyleItemIndex = (styleItems, style, colorDesc) =>
  styleItems.findIndex(item => item.style === style && item.colorDesc === colorDesc);

const updateExistingStyleItem = (styleItem, checkoutItem, warehouse, totalPrice) => {
  const updatedStyleItem = { ...styleItem };
  updatedStyleItem.warehouse = styleItem.warehouse !== warehouse ? "Multiple Warehouses" : warehouse;
  
  const existingSizeEntryIndex = updatedStyleItem.sizeEntries.findIndex(entry => entry.size === checkoutItem.size);
  
  if (existingSizeEntryIndex > -1) {
    updatedStyleItem.sizeEntries[existingSizeEntryIndex] = updateSizeEntry(updatedStyleItem.sizeEntries[existingSizeEntryIndex], checkoutItem, totalPrice);
  } else {
    updatedStyleItem.sizeEntries.push(createNewSizeEntry(checkoutItem, totalPrice));
  }

  return updatedStyleItem;
};

const updateSizeEntry = (sizeEntry, checkoutItem, totalPrice) => ({
  ...sizeEntry,
  quantity: sizeEntry.quantity + Number(checkoutItem.quantity),
  totalPrice: sizeEntry.totalPrice + totalPrice
});

const createNewSizeEntry = (checkoutItem, totalPrice) => ({
  size: checkoutItem.size,
  quantity: Number(checkoutItem.quantity),
  totalPrice: totalPrice
});

const createNewStyleItem = (checkoutItem, warehouse, totalPrice) => ({
  style: checkoutItem.style,
  colorDesc: checkoutItem.colorDesc,
  description: checkoutItem.description,
  dgiStyleSKU: checkoutItem.dgi_style_sku,
  masterColor: checkoutItem.master_color,
  warehouse: warehouse,
  sizeEntries: [createNewSizeEntry(checkoutItem, totalPrice)]
});

function SideVendorCart({ vendorCode, vendorData, totals, hidePrices }) {
  const [isCartOpen, setIsCartOpen] = useState(false);

  const formattedSubtotal = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(totals?.subtotal || 0);

  const styleItems = processCheckoutItems(vendorData, vendorCode);

  if (vendorData?.loading === LOADING_STATES.LOADING_STATUS) {
    return <Skeleton variant="rounded" height={48} />;
  }

  return (
    <Accordion
      onChange={() => setIsCartOpen(!isCartOpen)}
      className={styles.sideVendorCart}
      disabled={styleItems.length < 1}
      sx={{
        "&.Mui-expanded": {
          margin: "0px",
        },
        "&.MuiPaper-root": {
          marginTop: "0px",
          borderRadius: "5px",
        },
        borderTop: "none",
        border: "1px solid #d2d6da",
        boxShadow: "none",
        "&:hover": {
          boxShadow:
            "0 10px 20px rgba(50, 50, 93, 0.07), 0 3px 10px rgba(0, 0, 0, 0.05)",
        },
        transition: "box-shadow 0.3s",
        "&::before": {
          content: "none",
        },
        "&.Mui-disabled": {
          backgroundColor: "#dce3e8",
          color: "#999",
          "&:hover": {
            boxShadow: "none",
            cursor: "normal",
          },
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="text-charcoal" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          padding: "4px 0px 4px 8px",
          "&.MuiAccordionSummary-root": {
            display: "flex",
            alignItems: "center",
            gap: "0px",
            height: "48px",
            "&.Mui-disabled": {
              background: "rgba(239, 243, 245, 1)",
            },
          },
          "&.Mui-expanded": {
            minHeight: "48px",
            padding: "4px 0px 4px 8px",
          },
          "& .MuiAccordionSummary-content": {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0px",
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            order: 2,
          },
          borderRadius: "5px",
        }}
      >
        <div className="flex flex-row items-center gap-x-[6px]">
          <VendorLogo vendorCode={vendorCode} />
          <CartHeaderText
            content={` (${totals?.totalItems || 0})`}
            sx={{ order: 1 }}
          />
        </div>
        <CartHeaderText
          content={formattedSubtotal}
          hidePrices={hidePrices}
          sx={{ order: 3 }}
        />
      </AccordionSummary>
      <AccordionDetails
        sx={{
          cursor: "default",
          paddingTop: "12px",
          paddingBottom: "4px",
          borderTop: "dashed 1px #d2d6da",
          px: "8px",
        }}
      >
        <div className={styles.sideCheckoutItems}>
          {styleItems.map((styleItem, index) => (
            <SideCheckoutItem
              key={`${styleItem["style"]}_${index}`}
              styleItem={styleItem}
              hidePrices={hidePrices}
              isCartOpen={isCartOpen}
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default SideVendorCart;
