import React, { useState } from "react";
import styles from "./styles.module.scss";
import CheckoutItem from "./CheckoutItem";
import CartSummary from "./CartSummary";
import { LinearProgress, Tooltip, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Skeleton from "@mui/material/Skeleton";
import ShippingMinPopUp from "./ShippingMinPopUp";
import { LOADING_STATES } from "features/cartsSlice";
import {
  AB_WAREHOUSE_MAP,
  AS_WAREHOUSE_MAP,
  CM_WAREHOUSE_MAP,
  PG_WAREHOUSE_MAP,
  SM_WAREHOUSE_MAP,
  SS_WAREHOUSE_MAP,
  ASC_WAREHOUSE_MAP,
  ACC_WAREHOUSE_MAP,
  STA_WAREHOUSE_MAP,
  SLC_WAREHOUSE_MAP,
} from "utilities/parseInventoryData";

// TODO why is this necessary?
const VENDOR_WHSES = {
  ab: AB_WAREHOUSE_MAP,
  cm: CM_WAREHOUSE_MAP,
  sm: SM_WAREHOUSE_MAP,
  ss: SS_WAREHOUSE_MAP,
  as: AS_WAREHOUSE_MAP,
  pg: PG_WAREHOUSE_MAP,
  acc: ACC_WAREHOUSE_MAP,
  sta: STA_WAREHOUSE_MAP,
  slc: SLC_WAREHOUSE_MAP,
  asc: ASC_WAREHOUSE_MAP
};

export default function VendorCart({
  isDirectCart = false,
  freeShippingMin = 200,
  vendorCode,
  vendorName,
  vendorCartData
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const vendorLogoSrc = `https://d34c0c1in94n75.cloudfront.net/${vendorCode}_logo.png`;
  
  if (
    !vendorCartData ||
    vendorCartData?.loading === LOADING_STATES.LOADING_STATUS ||
    vendorCartData?.loading === LOADING_STATES.IDLE_STATUS
  ) {
    return <Skeleton variant="rectangular" height={48} />;
  }

  const createItemGroupings = (items) => {
    const newItemGroupings = {};
    for (const item of items) {
      const style = item.style;
      const subIdentifier =
        item.colorDesc || item.master_color || item.dimensions;
      // arbitrary key format but sufficient
      // we assume that colors don't have questions marks
      const groupingKey = style + "_" + subIdentifier?.replace(" ", "?");

      if (newItemGroupings[groupingKey]) {
        const groupItems = [...newItemGroupings[groupingKey]];
        groupItems.push(item);
        newItemGroupings[groupingKey] = groupItems;
      } else {
        newItemGroupings[groupingKey] = new Array(item);
      }
    }
    return Object.values(newItemGroupings);
  };

  // Display Value
  const itemGroups = vendorCartData?.items
    ? createItemGroupings(vendorCartData?.items)
    : [];

  let discountDSTotalPrice = 0.0;
  if (vendorCartData && vendorCartData["items"]) {
    for (const item of vendorCartData["items"]) {
      const itemWhse = item["warehouse"];
      const nonDSWhses = VENDOR_WHSES[vendorCode] || {};
      const isStandardWhse = Object.values(nonDSWhses).includes(itemWhse);
      if (isStandardWhse) {
        const itemTotal = item["totalPrice"];
        discountDSTotalPrice += itemTotal;
      }
    }
  }

  const cartTotalPrice = vendorCartData?.totalPrice ?? 0;
  // Display Value
  const subTotal = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(cartTotalPrice);

  const formattedDiscountDSTotal = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(discountDSTotalPrice);

  const newSubTotal = parseFloat(
    formattedDiscountDSTotal.replace(/[^0-9.-]+/g, "")
  );
  const progress = (newSubTotal / freeShippingMin) * 100; // Using numerical value for calculation
  // Display Value
  let freeShippingProgress = Math.min(progress, 100);
  // we want to refrain from making users think that a progress of 99% is 100%
  if (freeShippingProgress >= 95 && freeShippingProgress < 100) {
    freeShippingProgress = 95;
  }
  // if there is no shipping min, so we want to max out linear progress bar
  if (!freeShippingMin) {
    freeShippingProgress = 100
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const startExpanded = cartTotalPrice > 0;

  if (vendorCartData?.loading === true) {
    return <Skeleton variant="rectangular" height={48}></Skeleton>;
  }

  const reachedShippingMin = (freeShippingMin - discountDSTotalPrice <= 0) || (!freeShippingMin);

  return (
    <Accordion
      key={`${vendorCode}-accordion`}
      id={`${vendorCode}-accordion`}
      elevation={0}
      defaultExpanded={startExpanded}
      sx={{
        "&:first-of-type": {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        margin: 0,
        borderRadius: 0,
        borderLeft: !isDirectCart && "3px solid rgb(2,129,192)",
        "&:hover": {
          boxShadow:
            !isDirectCart &&
            "0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07)",
        },
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon className="text-charcoal" />}
        aria-controls={`${vendorCode}-content`}
        id={`${vendorCode}-header`}
        disableGutters={true}
        classes={{
          expanded: styles.vendorCartHeader,
          content: styles.vendorCartHeader,
          root: styles.vendorCartHeader,
        }}
        sx={{
          borderRight: isDirectCart ? "" : "1px solid #e0e3e9",
          borderTop: isDirectCart ? "" : "1px solid #e0e3e9",
          borderBottom: "1px solid rgba(225, 229, 230, 1)",
          position: "sticky",
          top: "0",
          zIndex: "5",
        }}
        className="bg-dustyBlue px-[10px]"
      >
        <div className={`${isDirectCart && "w-[20%]"} bg-dustyBlue p-[2px]`}>
          <img
            className={styles.vendorLogoImage}
            data-downsize={vendorCode === "slc"}
            src={vendorLogoSrc}
            alt={vendorName}
          />
        </div>
        <div className={styles.freeShippingStatus}>
          <span className={styles.price}>$0</span>
          <Tooltip
            title={
              <React.Fragment>
                {reachedShippingMin ? (
                  <>
                    <Typography className="text-base">
                      You've reached your free shipping minimum for {vendorName}
                      !
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: "0.6em" }}>
                      Some exclusions may apply. Proceed to checkout or view on
                      vendor site for exact shipping estimates.
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography>
                      Spend{" "}
                      <strong>{` $${(
                        (freeShippingMin || 0) - discountDSTotalPrice
                      ).toFixed(2)} `}</strong>
                      more to get FREE shipping with {vendorName}.
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: "0.6em" }}>
                      Some exclusions may apply. Proceed to checkout or view on
                      vendor site for exact shipping estimates.
                    </Typography>
                  </>
                )}
              </React.Fragment>
            }
            placement="top"
            classes={{ tooltip: styles.customTooltip }}
          >
            <LinearProgress
              variant="determinate"
              value={freeShippingProgress || 20}
              sx={{
                height: "16px",
                width: "300px",
                borderRadius: "8px",
                backgroundColor: "#CCCCCC",
                "& .MuiLinearProgress-bar": {
                  backgroundColor:
                    freeShippingProgress === 100
                      ? "rgb(0,164,0, 0.57)" // actionGreen
                      : "rgba(0, 110, 165, 1)", // blueblue
                },
              }}
            />
          </Tooltip>
          <span className={styles.price}>{`$${freeShippingMin || "0"}`}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className={styles.vendorCartData}
        sx={{
          borderRight: isDirectCart
            ? "none"
            : "1px solid rgba(225, 229, 230, 1)", // grayscaleSilver
          borderBottom: isDirectCart
            ? "none"
            : "1px solid rgba(225, 229, 230, 1)", // grayscaleSilver
          paddingX: "0",
        }}
      >
        <div className={styles.checkoutItems}>
          {itemGroups.map((checkoutItemEntries, index) => (
            <CheckoutItem
              key={index}
              lastItem={index === itemGroups.length - 1}
              vendorCode={vendorCode}
              checkoutItemEntries={checkoutItemEntries}
              isDirectCart={isDirectCart}
            />
          ))}
        </div>
        {!isDirectCart && (
          <CartSummary
            subTotal={subTotal}
            vendorName={vendorName}
            vendorCartData={vendorCartData}
          />
        )}
      </AccordionDetails>
      <ShippingMinPopUp open={isDialogOpen} onClose={handleCloseDialog} />
    </Accordion>
  );
}
