import React, { useEffect, useState, useRef } from "react";

import styles from "./styles.module.scss";
import { createCartEntry } from "../../utilities/createCartEntry";
import { TableCell, Typography } from "@mui/material";
import Box from "@mui/material/Box";

const CaseQtyIncrement = ({ caseQtyRequired, caseQtyIncrement }) => {
  if (!caseQtyRequired) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "50px" }}>
      <Typography
        sx={{ fontWeight: "600", textAlign: "center", fontSize: "13px" }}
      >
        {`(${caseQtyIncrement})*`}
      </Typography>
    </Box>
  );
};

export default function InventoryNumberCell({
  vendorCode,
  inventoryEntry,
  itemPrice,
  warehouse,
  warehouseCode,
  setCheckoutEntries,
  setInvalidTableCells,
  bodyRowIndex,
  setSelectedBodyRowIndex,
  triggerSaveCart,
  canSaveToCart,
  selectedVendorCartData,
  shouldUpdateCell,
  dgi_style_sku,
  isDirectVendor,
}) {
  const [itemQuantity, setItemQuantity] = useState("");
  const [cellItemNumber, setCellItemNumber] = useState(null);
  const [cartItem, setCartItem] = useState({});
  const [originalQuantityNumber, setOriginalQuantityNumber] = useState(0);
  const userChangedInput = useRef(null);
  const initializedByFetch = useRef(null);
  const [isValid, setIsValid] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const entryKey =
    inventoryEntry?.sizeLabel + "_" + warehouse.replaceAll(" ", ""); // arbitrary but sufficient to differentiate items

  useEffect(() => {
    // passive update
    if (shouldUpdateCell) {
      updateItemData();
    }
  }, [inventoryEntry?.itemNumber, shouldUpdateCell]);

  const updateCheckoutEntries = (newItemQuantity, newCellItemNumber) => {
    if (
      Number.isInteger(newItemQuantity) &&
      newItemQuantity >= 0 &&
      newCellItemNumber
    ) {
      let updatingEntry = false;

      if (userChangedInput.current) {
        if (initializedByFetch.current && originalQuantityNumber > 0) {
          updatingEntry = true;
        } else {
          updatingEntry = false;
        }
      } else {
        // entry is initialized based off cart data fetch
        updatingEntry = true;
      }

      if (userChangedInput.current) {
        const isAddingZero = newItemQuantity === 0 && !updatingEntry; // don't add entries where we just add 0 of some item
        const isAddingOriginalQuantity =
          newItemQuantity === originalQuantityNumber; // don't add original quantity from cart fetch
        if (isAddingZero || isAddingOriginalQuantity) {
          setCheckoutEntries((prevCheckoutEntries) => {
            const newCheckoutEntries = { ...prevCheckoutEntries };
            delete newCheckoutEntries[entryKey];
            return newCheckoutEntries;
          });
        } else {
          const checkoutEntry = isDirectVendor
            ? {
                direct_vendor: vendorCode,
                dgi_style_sku,
                vendorCode,
                warehouse,
                unitPrice: itemPrice,
                quantity: newItemQuantity,
                updatingEntry,
                ...inventoryEntry,
              }
            : createCartEntry(
                vendorCode,
                warehouseCode,
                newItemQuantity,
                itemPrice,
                cartItem,
                updatingEntry,
                newCellItemNumber,
                inventoryEntry
              );

          setCheckoutEntries((prevCheckoutEntries) => ({
            ...prevCheckoutEntries,
            [entryKey]: checkoutEntry,
          }));
        }

        if (newItemQuantity === 0 && originalQuantityNumber === 0) {
          setItemQuantity("");
        }
      }
    }
  };

  const getItemQuantity = () => {
    if (!selectedVendorCartData?.items) {
      return null;
    }

    const vendorCartItems = selectedVendorCartData.items;
    const targetCartItem = findTargetCartItem(vendorCartItems);

    if (!targetCartItem) {
      return null;
    }

    const targetItemNumber = getTargetItemNumber(targetCartItem);

    return {
      quantity: targetCartItem.quantity,
      cartItemNumber: targetItemNumber,
      cartItem: targetCartItem,
    };
  };

  const findTargetCartItem = (vendorCartItems) => {
    return vendorCartItems.find((cartItem) => {
      if (vendorCode === "ss") {
        return isSameSSItem(cartItem);
      }
      if (vendorCode === "as") {
        return isSameASItem(cartItem);
      }
      return isSameSKUAndWarehouse(cartItem);
    });
  };

  const isSameSSItem = (cartItem) => {
    if (!inventoryEntry?.itemNumber || !cartItem.ss_sku_id_master) {
      return false;
    }
    const [itemSID, itemWHID, , itemSIDM] =
      inventoryEntry.itemNumber.split("_");
    return (
      itemSIDM === cartItem.ss_sku_id_master &&
      itemSID === cartItem.ss_site_id &&
      itemWHID === cartItem.ss_warehouse_id
    );
  };

  const isSameASItem = (cartItem) => {
    return (
      inventoryEntry?.itemNumber?.toString() ===
      cartItem.as_product_id?.toString()
    );
  };

  const isSameSKUAndWarehouse = (cartItem) => {
    const sameSKU = isSameSKU(cartItem);
    const sameWarehouse = isSameWarehouse(cartItem);
    return sameSKU && sameWarehouse;
  };

  const isSameSKU = (cartItem) => {
    if (!inventoryEntry?.itemNumber || !cartItem.itemNumber) {
      return false;
    }
    if (vendorCode === "pg") {
      const cellItemNumber = inventoryEntry.itemNumber
        .split(";")
        .slice(0, 3)
        .join(";");
      const cartItemNumber = cartItem.itemNumber
        .split(";")
        .slice(0, 3)
        .join(";");
      return cellItemNumber.toLowerCase() === cartItemNumber.toLowerCase();
    }
    return (
      inventoryEntry.itemNumber.toLowerCase() ===
      cartItem.itemNumber.toLowerCase()
    );
  };

  const isSameWarehouse = (cartItem) => {
    if (warehouseCode && cartItem.warehouseCode) {
      return (
        normalizeString(warehouseCode) ===
        normalizeString(cartItem.warehouseCode)
      );
    }
    if (warehouse && cartItem.warehouse) {
      return normalizeString(warehouse) === normalizeString(cartItem.warehouse);
    }
    return false;
  };

  const normalizeString = (str) => str.toLowerCase().replace(" ", "");

  const getTargetItemNumber = (targetCartItem) => {
    let targetItemNumber = targetCartItem.itemNumber.toLowerCase();
    if (vendorCode === "ss") {
      const colorParam = targetCartItem.colorDesc
        .toLowerCase()
        .replaceAll(" ", "_");
      const sidm = targetCartItem.ss_sku_id_master;
      targetItemNumber = `${targetItemNumber}_${sidm}_${colorParam}`;
    } else if (vendorCode === "as") {
      targetItemNumber = targetCartItem.id;
    }
    return targetItemNumber;
  };

  const updateItemData = () => {
    const newItemInfo = getItemQuantity();
    let newItemQuantity, newCellItemNumber;
    if (newItemInfo) {
      newItemQuantity = Boolean(inventoryEntry?.needToCallWhse)
        ? "Call"
        : newItemInfo.quantity;
      newCellItemNumber = newItemInfo.cartItemNumber;
      setItemQuantity(newItemQuantity);
      setOriginalQuantityNumber(newItemInfo.quantity);
      setCellItemNumber(newItemInfo.cartItemNumber);
      setCartItem(newItemInfo?.cartItem);
      initializedByFetch.current = true;
      setIsValid(true);
    } else {
      newItemQuantity = Boolean(inventoryEntry?.needToCallWhse) ? "Call" : "";
      newCellItemNumber = inventoryEntry?.itemNumber;
      setCellItemNumber(inventoryEntry?.itemNumber);
      setOriginalQuantityNumber(0);
      setItemQuantity(newItemQuantity);
      setCartItem({});
      setIsValid(true);
    }
    // update checkoutEntries state var
    updateCheckoutEntries(newItemQuantity, newCellItemNumber);
  };

  const onQuantityChange = (e) => {
    const inputValue = e.target.value;

    const regex = /^[0-9]*$/;

    if (inputValue === "" || regex.test(inputValue)) {
      let newQuantity;

      if (inputValue) {
        newQuantity = Number(inputValue);
      } else {
        newQuantity = 0;
      }

      let invalidCaseQty = false;
      if (Boolean(inventoryEntry?.caseQtyRequired)) {
        invalidCaseQty = newQuantity % inventoryEntry?.caseQtyIncrement !== 0;
      }

      const updateInvalidTableCells = setInvalidTableCells;
      if (newQuantity > Number(inventoryEntry?.stockNum) || invalidCaseQty) {
        setIsValid(false);
        updateInvalidTableCells((prevInvalidTableCells) => {
          const newInvalidTableCells = new Set(prevInvalidTableCells);
          newInvalidTableCells.add(entryKey);
          return newInvalidTableCells;
        });
      } else {
        setIsValid(true);
        updateInvalidTableCells((prevInvalidTableCells) => {
          const newInvalidTableCells = new Set(prevInvalidTableCells);
          newInvalidTableCells.delete(entryKey);
          return newInvalidTableCells;
        });
      }

      userChangedInput.current = true;
      setItemQuantity(newQuantity);
      // update checkoutEntries state var
      updateCheckoutEntries(newQuantity, cellItemNumber);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    setSelectedBodyRowIndex(bodyRowIndex);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setSelectedBodyRowIndex(null);
  };

  const errorClass = !isValid ? styles.error : "";
  const focusedClass = isFocused && isValid ? styles.focused : "";
  const highlightClass =
    itemQuantity !== "" &&
    isValid &&
    Number(itemQuantity) &&
    Number(itemQuantity) >= 0 &&
    !isFocused
      ? styles.greenHighlight
      : "";
  let additionalClass = "";
  if (errorClass) {
    additionalClass = errorClass;
  } else if (focusedClass) {
    additionalClass = focusedClass;
  } else if (highlightClass) {
    additionalClass = highlightClass;
  }

  return (
    <TableCell sx={{ padding: "10px" }}>
      <div
        className={styles.inventoryNumberContainer}
        style={{
          display:
            inventoryEntry?.stockNum === "null" ||
            inventoryEntry?.stockNum === "-"
              ? "none"
              : "flex",
        }}
      >
        <div className="text-center">
          <input
            ref={inputRef}
            className={`${styles.inventoryInput} ${additionalClass}`}
            data-is-valid={isValid && !Boolean(inventoryEntry?.needToCallWhse)}
            data-need-to-call={Boolean(inventoryEntry?.needToCallWhse)}
            type="tel"
            value={itemQuantity}
            disabled={Boolean(inventoryEntry?.needToCallWhse)}
            onChange={onQuantityChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={(e) => {
              if (e.key === "Enter" && canSaveToCart) {
                inputRef.current.blur(); // removes focus animation
                triggerSaveCart();
              } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                let newQuantity = parseInt(itemQuantity) || 0;
                if (e.key === "ArrowUp") {
                  newQuantity++;
                } else if (e.key === "ArrowDown" && newQuantity > 0) {
                  newQuantity--;
                }
                e.preventDefault();
                // setItemQuantity(newQuantity.toString());
                onQuantityChange({ target: { value: newQuantity.toString() } });
              }
            }}
          />
        </div>
        <div
          className={styles.inventoryNumber}
          data-case-qty-required={Boolean(inventoryEntry?.caseQtyRequired)}
          data-need-to-call={Boolean(inventoryEntry?.needToCallWhse)}
        >
          <span className="text-almostSM">{inventoryEntry?.stockNum}</span>
        </div>
        <CaseQtyIncrement
          caseQtyRequired={Boolean(inventoryEntry?.caseQtyRequired)}
          caseQtyIncrement={inventoryEntry?.caseQtyIncrement}
        />
      </div>
    </TableCell>
  );
}
