import React from 'react';
import { Box, Typography, Grid, Skeleton } from '@mui/material';
import { RenderSupplierRating } from 'integrations/SupplierRating';

const VendorCard = ({ vendor }) => {
  // Display skeleton if vendor is null
  if (!vendor) {
    return (
      <Box p={2} border="1px solid #ddd" borderRadius="8px" maxWidth="380px" mx="auto">
        <Skeleton variant="text" width="60%" height={32} />
        <Skeleton variant="rectangular" width="100%" height={20} sx={{ mt: 1, mb: 1 }} />
        {[...Array(5)].map((_, index) => (
          <Skeleton key={index} variant="text" width="80%" height={24} />
        ))}
      </Box>
    );
  }

  // Construct the logo URL using the vendor's code
  const logoUrl = `https://d34c0c1in94n75.cloudfront.net/${vendor.vendorCode}_logo.png`;

  return (
    <Box p={2} border="1px solid #ddd" borderRadius="8px" maxWidth="380px" minWidth="320px" mx="auto">
      {/* Header section with vendor name, rating, and logo */}
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography variant="h6">{vendor.vendorName || 'Vendor Name'}</Typography>
          <RenderSupplierRating vendorInfo={vendor} showReviewCount />
        </Box>
        <img
          src={logoUrl}
          alt={`${vendor.vendorName} logo`}
          width="100px"
          style={{
            objectFit: 'contain',
            alignSelf: 'flex-start',
            paddingTop: '6px',
          }}
        />
      </Box>

      {/* Display Vendor Information */}
      <Box mt={2}>
        {[
          { 
            label: "Free Shipping At", 
            value: vendor.freeShippingMinimum === 0 || vendor.freeShippingMinimum === '0' 
              ? <Typography variant="body1" color="textSecondary">N/A</Typography> 
              : `$${vendor.freeShippingMinimum}` 
          },
          { 
            label: "Standard Shipping", 
            value: vendor.standardShippingPrice === 0 || vendor.standardShippingPrice === '0' 
              ? 'FREE' 
              : `$${vendor.standardShippingPrice}` 
          },
          { label: "Location", value: vendor.address ? `${vendor.address.city}, ${vendor.address.state}` : 'N/A' },
          { label: "Unique Products", value: vendor.uniqueProducts || 'N/A' },
          { label: "Joined DGI", value: vendor.joinedDGI || 'N/A' },
          { label: "Categories", value: Array.isArray(vendor.categories) ? vendor.categories.join(', ') : (vendor.categories || 'N/A') },
        ].map((field, index) => (
          <Grid container key={index} mb={0.5}>
            <Grid item xs={6}>
              <Typography variant="body1" color="textSecondary">
                {field.label}:
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              {typeof field.value === 'string' ? (
                <Typography variant="body1">{field.value}</Typography>
              ) : (
                field.value
              )}
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default VendorCard;
