import React, { useMemo } from "react";

import styles from "./styles.module.scss";
import ProductPriceRow from "./ProductPriceRow.js";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import PaperBack from "./PaperBack.js";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useSelector } from "react-redux";
import RemoveIcon from '@mui/icons-material/Remove';

function ProductRows({
  vendorsArray,
  setOpenInvDetails,
  setSelectedVendorCode,
  masterColor,
  setVendorProducts,
  productStyle,
  id,
  areConnectionRows = false,
}) {
  const hidePrices = useSelector((state) => state.user?.user?.hide_prices);
  return (
    <>
      {vendorsArray.map((v, index) => (
        <ProductPriceRow
          key={`connectionrow-${id}-${index}`}
          dgi_style_sku={id}
          vendorCode={v.code}
          vendorName={v.name}
          setOpenInvDetails={setOpenInvDetails}
          setSelectedVendorCode={setSelectedVendorCode}
          masterColor={masterColor}
          setVendorProducts={setVendorProducts}
          productStyle={productStyle}
          isConnectionRow={areConnectionRows}
          hidePrices={hidePrices}
        />
      ))}
    </>
  );
}

const RenderProductRows = ({
  displayVendors,
  displaySuggestedVendors,
  isPricingAvailable,
  setOpenInvDetails,
  setSelectedVendorCode,
  masterColor,
  setVendorProducts,
  productStyle,
  id,
  showOnlySyncedVendors,
}) => {
  const priceRows = (
    <ProductRows
      vendorsArray={displayVendors}
      setOpenInvDetails={setOpenInvDetails}
      setSelectedVendorCode={setSelectedVendorCode}
      masterColor={masterColor}
      setVendorProducts={setVendorProducts}
      productStyle={productStyle}
      id={id}
    />
  );

  const connectionRows = (
    <ProductRows
      vendorsArray={displaySuggestedVendors}
      setOpenInvDetails={setOpenInvDetails}
      setSelectedVendorCode={setSelectedVendorCode}
      masterColor={masterColor}
      setVendorProducts={setVendorProducts}
      productStyle={productStyle}
      id={id}
      areConnectionRows={true}
    />
  );

  if (showOnlySyncedVendors) {
    return <>{priceRows}</>;
  }

  return (
    <>
      {isPricingAvailable ? (
        <>
          {priceRows}
          {connectionRows}
        </>
      ) : (
        <>
          {connectionRows}
          {priceRows}
        </>
      )}
    </>
  );
};

const ProductPrices = ({
  displayVendors,
  displaySuggestedVendors,
  hit,
  setOpenInvDetails,
  setSelectedVendorCode,
  openInvDetails,
  masterColor,
  setVendorProducts,
  cheapestAvailableVendor,
  showOnlySyncedVendors,
}) => {
  const { id, dgi_style_sku } = hit;

  const dgiStyle = dgi_style_sku || id;

  return (
    <div className={styles.productPrices}>
      <TableContainer
        className={styles.pricesTableContainer}
        sx={{
          flexGrow: 1,
          width: "360px",
          "@media (max-width: 768px)": {
            width: "100%",
          },
        }}
        component={PaperBack}
      >
        <Table
          sx={{ tableLayout: "fixed", width: "100%" }}
          aria-label="simple table"
        >
          <TableBody
            id={`price-table-body-${dgiStyle}`}
            className={styles.pricesTableBody}
          >
            <RenderProductRows
              displayVendors={displayVendors}
              displaySuggestedVendors={displaySuggestedVendors}
              isPricingAvailable={Boolean(cheapestAvailableVendor)}
              setOpenInvDetails={setOpenInvDetails}
              setSelectedVendorCode={setSelectedVendorCode}
              masterColor={masterColor}
              setVendorProducts={setVendorProducts}
              productStyle={hit.master_style}
              id={dgiStyle}
              showOnlySyncedVendors={showOnlySyncedVendors}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          height: "40px",
          flexShrink: 0,
          width: "100%",
          borderTop: "1px dashed #d2d6da",
          borderBottom: 0,
          borderLeft: 0,
          borderRight: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          flexShrink: 0,
          "&:hover": {
            backgroundColor: "#f4f4f4",
          },
          "@media (max-width:768px)": {
            display: "none",
          },
        }}
        onClick={() => setOpenInvDetails(!openInvDetails)}
      >
        <Button
          disableFocusRipple
          disableRipple
          sx={{
            textTransform: "none",
            padding: "1px 8px 1px 8px",
            backgroundColor: "transparent",
          }}
          className="text-blueblue text-sm hover:bg-transparent"
        >
          {openInvDetails ? (
            <RemoveIcon
              sx={{ color: "inherit", marginRight: 1, fontSize: "1.25rem" }}
            />
          ) : (
            <AddShoppingCartIcon
              sx={{ color: "inherit", marginRight: 1, fontSize: "1.25rem" }}
            />
          )}
          {openInvDetails ? "Close inventory" : "Shop"}
        </Button>
      </Box>
    </div>
  );
};

export default ProductPrices;
