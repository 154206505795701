import React, { useEffect, useState } from "react";
import lilProductClient from "../../axios/lilProductClient";
import endpoints from "../../axios/endpoints";
import { processProductData } from "./ProductPage";
import { useUserStateContext, useDataContext } from "App";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Box,
  Typography,
  Skeleton,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import { getURLColor, constructImageUrl } from "utilities/imageUrlUtils";
import getStyleNumber from "utilities/getStyleNumber";
import { formatBrandName } from "utilities/formatBrandName";
import {
  fetchProductStartBatch,
  fetchProductFailure,
  selectCheapestVendorPrice,
} from "features/productsSlice";
import {
  vendorPayloadFields,
  colorFields,
} from "features/useSocketPayloadSender";
import { useSelector, useDispatch } from "react-redux";
import { formatPrice } from "utilities/formatPrice";
import { selectStyle, fetchStyleSuccess } from "features/styleSlice";
import { usePostHog } from "posthog-js/react";
import {
  getConnectedVendors,
  getExistingProductKeys,
  createSocketPayload,
  sendPayload,
} from "features/useSocketPayloadSender";
import { buildProductUrl } from "utilities/navigation";

export function RenderStartingPrices({
  dgiStyle,
  masterColor,
  vendorCodes,
  fetchingSimilarsPrices,
  priceText=undefined,
  isRecommendation=true
}) {
  const cheapestPrice = useSelector((state) =>
    selectCheapestVendorPrice(state, dgiStyle, masterColor, vendorCodes)
  );

  // TODO: remove this prop entirely once we implement price tickers
  if (!isRecommendation) {
    return null;
  }

  let renderStartingAtPrice = null;
  if (fetchingSimilarsPrices && cheapestPrice < Infinity) {
    renderStartingAtPrice = (
      <Typography className="text-base text-denim font-semibold">
        {`${priceText ? priceText : "Starting at"} ${formatPrice(cheapestPrice)}`}
      </Typography>
    );
  } else if (fetchingSimilarsPrices && !(cheapestPrice < Infinity)) {
    renderStartingAtPrice = (
      <Box display="flex" alignItems="center" gap={1.5}>
        <Typography className="text-charcoal text-base">{priceText ? priceText : "Starting at"}</Typography>
        <CircularProgress size={15} />
      </Box>
    );
  }

  return (<div className="mb-[5px]">{renderStartingAtPrice}</div>);

};

export function AdditionalProductDetails({
  productDetails,
  vendorCodes,
  additionalStyle,
  masterColor,
  fetchingSimilarsPrices,
  isRecommendation=true
}) {
  const numberOfDetails = productDetails.length;
  const getDetailStyling = (index) => {
    if (index > 0) {
      return "text-sm";
    }
    return "text-base font-semibold";
  };

  return (
    <div className="flex flex-col w-[210px]">
      <RenderStartingPrices
        dgiStyle={additionalStyle}
        masterColor={masterColor}
        vendorCodes={vendorCodes}
        fetchingSimilarsPrices={fetchingSimilarsPrices}
        isRecommendation={isRecommendation}
      />
      {productDetails.map((detail, index) => (
        <Typography
          key={index}
          className={`detailText ${getDetailStyling(index)}`}
        >
          {detail}
          {index < numberOfDetails - 1 && <br />}
        </Typography>
      ))}
    </div>
  );
}

function AdditionalProductImage({ additionalStyle, firstColor }) {
  const [displayImage, setDisplayImage] = useState(true);

  if (!firstColor || !displayImage) {
    return (
      <Box className="flex flex-col justify-center items-center h-[195px] w-auto">
        <Box
          className={`
            flex flex-col items-center justify-center gap-[6px]
            max-h-[175px] min-h-[175px] p-[12px] bg-grayscaleSilver rounded-[4px]`}
        >
          <Typography className="text-5xl text-grayscaleStone">DGI</Typography>
          <Typography className="text-lg text-grayscaleStone">
            Image coming soon
          </Typography>
        </Box>
      </Box>
    );
  }

  const urlColor = getURLColor(firstColor);
  const imageUrl = constructImageUrl(additionalStyle, urlColor, 0);

  return (
    <Box
      className={`
        flex flex-col justify-center 
        h-[195px] w-full bg-white 
        border border-solid border-grayscaleSilver rounded-[3px]`}
    >
      <img
        src={imageUrl}
        alt={null}
        onError={() => setDisplayImage(false)}
        style={{
          display: "inline-block",
          height: "auto",
          maxHeight: "100%",
          margin: 0,
          maxWidth: "100%",
          width: "auto",
          objectFit: "contain",
        }}
      />
    </Box>
  );
}

function AdditionalProductCard({
  additionalStyle,
  caseType,
  setSimilarProductData,
  vendorCodes,
  fetchingSimilarsPrices,
  originalVendorStyle,
  originalDGIStyleSKU,
  originalProductBrand,
  originalProductColor,
}) {
  const [productDetails, setProductDetails] = useState([]);
  const [loadingProduct, setLoadingProduct] = useState(true);
  const { userCreds } = useUserStateContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const location = useLocation();

  const navigateToProductPage = () => {
    // track whether user acted on recommendation
    const [recommendationStyle, recommendationBrand] = productDetails?.[0]
      ?.split("•")
      .map((detail) => detail.trim());
    posthog?.capture("navigated_to_recommendation", {
      recommendation_vendor_style: recommendationStyle,
      recommendation_dgi_style: additionalStyle,
      recommendation_brand: recommendationBrand,
      recommendation_color: firstColor?.name,
      recommendation_type: caseType,
      original_vendor_style: originalVendorStyle,
      original_dgi_style: originalDGIStyleSKU,
      original_brand: originalProductBrand,
      original_color: originalProductColor,
      source: location.pathname,
    });
    navigate(buildProductUrl(additionalStyle, firstColor?.name));
  };

  // Select the style from the Redux store
  let styleData = useSelector((state) => selectStyle(state, additionalStyle));

  useEffect(() => {
    (async () => {
      // If not, fetch from the API
      try {
        if (!styleData) {
          setLoadingProduct(true);
          const headers = {
            "User-Identifier": userCreds.cognitoID,
          };
          const productResp = await lilProductClient.get(
            endpoints.getProductInfo(additionalStyle),
            { headers }
          );

          styleData = processProductData(productResp?.data);

          // Dispatch to Redux
          dispatch(fetchStyleSuccess({ styleObj: styleData }));
        }
        setLoadingProduct(false);
        if (caseType === "similar") {
          setSimilarProductData((prevData) => {
            const alreadyIncluded = prevData.find((item) => {
              return item?.dgi_style_sku === additionalStyle;
            })
            if (alreadyIncluded) {
              return prevData;
            }
            return prevData.concat([styleData]);
          });
        }
        setProductDetails(() => {
          const styleNum = getStyleNumber(styleData);
          const styleBrand = formatBrandName(styleData?.master_brand);
          const shortDesc = styleData?.master_short_description;
          return [`${styleNum} • ${styleBrand}`, shortDesc];
        });
      } catch (error) {
        console.error(
          `Failed to get product data for ${caseType} style ${additionalStyle}: ${error.message}`
        );
        setLoadingProduct(false);
      }
    })();
  }, [additionalStyle, dispatch]);


  if (loadingProduct) {
    return (
      <Skeleton
        className="rounded-[10px]"
        variant="rectangular"
        width={225}
        height={195}
      />
    );
  }

  const firstColor = styleData?.colors.length > 0 && styleData.colors[0];

  return (
    <Box
      key={additionalStyle}
      className="flex flex-col items-center gap-[18px] cursor-pointer p-[6px]"
      sx={{
        "&:hover": {
          ".detailText": {
            color: "rgba(0, 110, 165, 1)", // blueblue
          },
        },
      }}
      onClick={navigateToProductPage}
    >
      <AdditionalProductImage
        additionalStyle={additionalStyle}
        firstColor={firstColor}
      />
      <AdditionalProductDetails
        productDetails={productDetails}
        vendorCodes={vendorCodes}
        additionalStyle={additionalStyle}
        masterColor={firstColor?.["name"]}
        fetchingSimilarsPrices={fetchingSimilarsPrices}
      />
    </Box>
  );
}

const getMasterColor = (productData) => {
  return productData?.colors[0]; // first color
}

function AdditionalStyles({
  caseType,
  sectionTitle,
  additionalStyles,
  sendJsonMessage,
  styleNumber,
  dgiStyleSKU,
  productBrand,
  productColor,
}) {
  const { userCreds } = useUserStateContext();
  const [fetchingSimilarsPrices, setFetchingSimilarsPrices] = useState(false);
  const [similarProductData, setSimilarProductData] = useState([]);
  const productKeys = similarProductData.map((productData) => ({
    dgi_style_sku: productData["dgi_style_sku"],
    master_color: productData["colors"][0]["name"],
  }));
  const dispatch = useDispatch();
  const hidePrices = useSelector((state) => state.user?.user?.hide_prices);
  const posthog = usePostHog();
  const location = useLocation();
  const { connectedVendorCodes } = useDataContext();
  const vendorCodes = getConnectedVendors(connectedVendorCodes);

  // Reset state when we jump across product pages
  useEffect(() => {
    setSimilarProductData((prevData) => {
      return prevData.filter((item) =>
        additionalStyles.includes(item?.dgi_style_sku)
      );
    });
    setFetchingSimilarsPrices(false);
  }, [styleNumber]);

  const fetchSimilarsPrices = () => {
    // track clicks for most basic analytic on button
    posthog?.capture("fetch_similars_prices", {
      original_vendor_style: styleNumber,
      original_dgi_style: dgiStyleSKU,
      original_brand: productBrand,
      original_color: productColor,
      source: location.pathname,
    });
    setFetchingSimilarsPrices(true);
    const userId = userCreds?.cognitoID;
    const existingProductKeys = getExistingProductKeys(
      dispatch,
      productKeys,
      vendorCodes
    );
    const payload = createSocketPayload(
      dispatch,
      userId,
      existingProductKeys,
      similarProductData,
      vendorCodes,
      getMasterColor
    );
    sendPayload(sendJsonMessage, payload);
  };

  const displaySimilarsPricesBtn =
    caseType === "similar" &&
    additionalStyles.length > 0 &&
    productKeys.length === additionalStyles.length &&
    !hidePrices;

  return (
    <Box padding="1rem" marginTop="1rem">
      <Stack
        direction="row"
        spacing={1.75}
        alignItems="center"
        className={`
        border border-solid border-charcoal 
        border-t-0 border-r-0 border-l-0 pb-[5px]`}
      >
        <Typography className="text-xl text-charcoal">
          {sectionTitle}
        </Typography>
        {displaySimilarsPricesBtn && (
          <Button
            onClick={() => fetchSimilarsPrices()}
            className={`
              normal-case text-base text-grayscaleWhite 
              bg-blueblue hover:bg-denim py-[2px]
              ${fetchingSimilarsPrices && "bg-grayscaleSilver"}`}
            marginBottom="5px"
            disabled={fetchingSimilarsPrices}
          >
            See Prices
          </Button>
        )}
      </Stack>
      <Box className="mt-[1.5rem] flex flex-wrap justify-start items-start gap-[55px]">
        {additionalStyles.map((additionalStyle) => (
          <AdditionalProductCard
            key={additionalStyle}
            additionalStyle={additionalStyle}
            caseType={caseType}
            setSimilarProductData={setSimilarProductData}
            vendorCodes={vendorCodes}
            fetchingSimilarsPrices={fetchingSimilarsPrices}
            originalVendorStyle={styleNumber}
            originalDGIStyleSKU={dgiStyleSKU}
            originalProductBrand={productBrand}
            originalProductColor={productColor}
          />
        ))}
      </Box>
    </Box>
  );
}

export default AdditionalStyles;
