import React from "react";
import {
  Box,
  Container,
  Typography,
  Alert,
} from "@mui/material";
import DirectVendorsHeader from "pages/directVendors/DirectVendorsHeader";
import { useGetDirectVendorsQuery } from "features/baseApi";
import VendorGrid from "./VendorGrid";

const DirectVendors = () => {
  const { data, isLoading, error } = useGetDirectVendorsQuery({});

  // Use the vendors array directly without duplicating
  const vendors = data?.vendors || [];

  if (isLoading) {
    return (
      <Container maxWidth="lg">
        <DirectVendorsHeader />
        <VendorGrid skeletonCount={6} />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <DirectVendorsHeader />
        <Alert severity="error" sx={{ mt: 2 }}>
          Error loading vendors: {error.message}
        </Alert>
      </Container>
    );
  }

  if (!vendors.length) {
    return (
      <Box px="1rem">
        <DirectVendorsHeader />
        <Alert severity="info" sx={{ mt: 2 }}>
          No vendors found.
        </Alert>
      </Box>
    );
  }

  return (
    <Box px="1rem">
      <DirectVendorsHeader />
      <VendorGrid vendors={vendors} />
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mt: 6, mb: 4 }}
      >
        Showing {vendors.length} of {data.count || vendors.length} vendors
      </Typography>
    </Box>
  );
};

export default DirectVendors;
