import React, { Suspense, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Box,
  TableRow,
  TableCell,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  getVendorName,
  highlightText,
  shouldIncludeOrder,
} from "./orderSearchUtilities";
import {
  renderTrackingNumbers,
  renderTrackingNumberIcon,
} from "./TrackingNumberLogic";

const OrderTableLineItems = React.lazy(() => import("./OrderTableLineItems"));

const formatToCurrency = (value) => {
  const number = formatToNumber(value);
  if (isNaN(number)) {
    return "Invalid input";
  }
  return `$${number.toFixed(2)}`;
};

const formatToNumber = (value) => {
  if (typeof value !== "string") {
    return value;
  }
  return parseFloat(value.replace(/[$\s]/g, ""));
};

const LoadingFallback = () => (
  <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
    <CircularProgress />
  </Box>
);

const OrderTablePO = ({ order, index, searchInput, numOfTableHeaders }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  if (!shouldIncludeOrder(order, searchInput)) {
    return null;
  }

  const handleAccordionChange = (event, expanded) => {
    setIsExpanded(expanded);
  };

  return (
    <TableRow key={index}>
      <TableCell colSpan={numOfTableHeaders.length} padding="none">
        <Box
          sx={{
            "@media (max-width: 500px)": {
              fontSize: "14px", // Apply 14px font size globally for screen widths below 500px
            },
          }}
        >
          <Accordion
            key={index}
            sx={{
              boxShadow: "none", // Remove shadow on Accordion root
            }}
            onChange={handleAccordionChange}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`order-content-${index}`}
              id={`order-header-${index}`}
              sx={{
                width: "100%",
                padding: 0,
                paddingLeft: "1rem",
                flexShrink: 1, // Allow the AccordionSummary to shrink
                minWidth: 0, // Prevents it from maintaining a minimum width that is too large
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                  display: "flex",
                  flexWrap: "wrap", // Allow content to wrap
                  overflow: "hidden", // Prevent overflow causing layout issues
                  flexShrink: 1, // Allow content to shrink
                  minWidth: 0, // Prevent content from enforcing a minimum width
                },
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "#e8e8e8",
                },
                "&.Mui-expanded": {
                  backgroundColor: "#e8e8e8",
                },
                // Use sx media query to conditionally hide the expand icon below 600px
                "@media (max-width: 650px)": {
                  "& .MuiAccordionSummary-expandIconWrapper": {
                    display: "none",
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  padding: 0,
                  "& .MuiAccordionSummary-content": {
                    margin: 0,
                  },
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#e8e8e8",
                  },
                  "@media (max-width: 500px)": {
                    justifyContent: "flex-end", // Right-justify all content below 500px
                  },
                }}
              >
                <Box
                  sx={{
                    width: "11%",
                    textAlign: "left",
                    minWidth: 0, // Allow the box to shrink below its content size
                    flexShrink: 1, // Allow shrinking when space is limited
                    "@media (max-width: 1000px)": {
                      width: "15%", // Increase width below 1000px
                    },
                    "@media (max-width: 750px)": {
                      width: "18%", // Increase width below 1000px
                    },
                    "@media (max-width: 500px)": {
                      width: "22%", // Increase width below 1000px
                      textAlign: "left", // Right-align text below 500px
                    },
                  }}
                >
                  {highlightText(order.OrderDate?.split("#")[0], searchInput)}
                </Box>
                <Box
                  sx={{
                    width: "13%",
                    textAlign: "left",
                    minWidth: 0,
                    flexShrink: 1,
                    "@media (max-width: 1000px)": {
                      width: "18%", // Increase width below 1000px
                    },
                    "@media (max-width: 750px)": {
                      width: "22%", // Increase width below 1000px
                    },
                    "@media (max-width: 500px)": {
                      width: "32%", // Increase width below 1000px
                      textAlign: "center", // Right-align text below 500px
                    },
                  }}
                >
                  {highlightText(
                    getVendorName(order.vendor) ||
                      order?.vendorName ||
                      order?.vendor,
                    searchInput,
                  )}
                </Box>
                <Box
                  sx={{
                    width: "11%",
                    textAlign: "left",
                    minWidth: 0,
                    flexShrink: 1,
                    "@media (max-width: 1000px)": {
                      width: "17%", // Increase width below 1000px
                    },
                    "@media (max-width: 750px)": {
                      width: "22%", // Increase width below 1000px
                    },
                    "@media (max-width: 500px)": {
                      width: "29%", // Increase width below 1000px
                      textAlign: "right", // Right-align text below 500px
                    },
                  }}
                >
                  {highlightText(order.poNumber, searchInput)}
                </Box>
                <Box
                  sx={{
                    width: "14%",
                    textAlign: "left",
                    minWidth: 0,
                    flexShrink: 1,
                    "@media (max-width: 1000px)": {
                      display: "none", // Hide Order # below 1000px
                    },
                  }}
                >
                  {highlightText(order.orderNumber, searchInput)}
                </Box>
                <Box
                  sx={{
                    width: "15%",
                    textAlign: "left",
                    minWidth: 0,
                    flexShrink: 1,
                    "@media (max-width: 1000px)": {
                      display: "none", // Hide Invoice # below 1000px
                    },
                  }}
                >
                  {highlightText(order.invoiceNumber, searchInput)}
                </Box>
                <Box
                  sx={{
                    width: { xs: "12%", md: "11%" },
                    textAlign: "left",
                    minWidth: 0,
                    flexShrink: 1,
                    "@media (max-width: 750px)": {
                      display: "none", // Hide orderItems field below 750px
                    },
                    "@media (max-width: 1000px)": {
                      width: "14%", // Increase width below 1000px
                    },
                  }}
                >
                  {order?.orderItems?.length}
                </Box>
                <Box
                  sx={{
                    width: { xs: "16%", md: "13%" },
                    textAlign: "left",
                    minWidth: 0,
                    flexShrink: 1,
                    "@media (max-width: 750px)": {
                      width: "20%", // Adjust width for icon
                    },
                    "@media (max-width: 1000px)": {
                      width: "16%", // Increase width below 1000px
                    },
                    "@media (max-width: 500px)": {
                      width: "20%", // Increase width below 500px
                      textAlign: "right", // Right-align tracking number and icon
                      paddingRight: "1rem", // Add padding to the right of the icon
                    },
                  }}
                >
                  {window.innerWidth <= 750
                    ? renderTrackingNumberIcon(order.trackingNumbers)
                    : renderTrackingNumbers(order.trackingNumbers, searchInput)}
                </Box>
                <Box
                  sx={{
                    width: { xs: "8%", md: "6%" },
                    textAlign: "right",
                    minWidth: 0,
                    flexShrink: 1,
                    "@media (max-width: 1400px)": {
                      width: { xs: "10%", md: "8%" },
                    },
                    "@media (max-width: 1000px)": {
                      width: "14%", // Increase width below 1000px
                    },
                    "@media (max-width: 500px)": {
                      display: "none", // Hide the Total field below 500px
                    },
                  }}
                >
                  {formatToCurrency(order?.total)}
                </Box>
              </Box>
            </AccordionSummary>
            {isExpanded && (
              <Suspense fallback={<LoadingFallback />}>
                <OrderTableLineItems
                  order={order}
                  searchInput={searchInput}
                  vendor={order.vendor}
                />
              </Suspense>
            )}
          </Accordion>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default OrderTablePO;
