import React, { useEffect, useState } from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import lilProductClient from "../axios/lilProductClient";
import endpoints from "../axios/endpoints";
import { constructImageUrl, getURLColor } from "utilities/imageUrlUtils";
import getStyleNumber from "utilities/getStyleNumber";
import { formatBrandName } from "utilities/formatBrandName";
import { usePostHog } from "posthog-js/react";
import { useDataContext, useUserStateContext } from "App";
import { AdditionalProductDetails } from "pages/product/AdditionalStyles";
import {
  getConnectedVendors,
  getExistingProductKeys,
  createSocketPayload,
  sendPayload,
} from "features/useSocketPayloadSender";
import { useDispatch, useSelector } from "react-redux";
import { useWebsocketContext } from "authsignin/AuthedPage";
import { selectStyle, fetchStyleSuccess } from "features/styleSlice";
import { processProductData } from "pages/product/ProductPage";
import { buildProductUrl } from "utilities/navigation";

export function SimilarProductCard({
  dgiStyleSku,
  originalVendorStyle,
  originalDGIStyleSKU,
  originalProductColor,
  originalProductBrand,
  dispatch,
  vendorCodes,
  userId,
  sendJsonMessage,
}) {
  const [productDetails, setProductDetails] = useState([]);
  const [loadingProduct, setLoadingProduct] = useState(true);
  const [displayImage, setDisplayImage] = useState(true);
  const navigate = useNavigate();
  const posthog = usePostHog();
  const location = useLocation();

  // Select the style from the Redux store
  let styleData = useSelector((state) => selectStyle(state, dgiStyleSku));

  const fetchSimilarPrice = (productData, availableColors) => {
    const getMasterColor = (availableColors) => {
      return availableColors?.[0];
    }
    const productKeys = [
      {
        dgi_style_sku: productData["dgi_style_sku"],
        master_color: getMasterColor(availableColors),
      },
    ];
    const existingProductKeys = getExistingProductKeys(
      dispatch,
      productKeys,
      vendorCodes
    );
    const payload = createSocketPayload(
      dispatch,
      userId,
      existingProductKeys,
      [productData],
      vendorCodes,
      () => getMasterColor(availableColors)
    );
    sendPayload(sendJsonMessage, payload);
  };

  useEffect(() => {
    (async () => {
      try {
        if (!styleData) {
          setLoadingProduct(true);
          const productResp = await lilProductClient.get(
            endpoints.getProductInfo(dgiStyleSku)
          );
          styleData = processProductData(productResp?.data);

          // Dispatch to Redux
          dispatch(fetchStyleSuccess({ styleObj: styleData }));
        }

        // Get style, short description, and long description
        setProductDetails(() => {
          const styleNum = getStyleNumber(styleData);
          const styleBrand = formatBrandName(styleData?.master_brand);
          const shortDesc = styleData?.master_short_description;
          return [`${styleNum} • ${styleBrand}`, shortDesc];
        });

        fetchSimilarPrice(styleData, styleData?.colors);
      } catch (error) {
        console.error(
          `Failed to get product data for style ${dgiStyleSku}: ${error.message}`
        );
      } finally {
        setLoadingProduct(false);
      }
    })();
  }, [dgiStyleSku]);

  const firstColor = styleData?.colors.length > 0 && styleData.colors[0];

  const navigateToProductPage = () => {
    // track whether user acted on recommendation
    const [recommendationStyle, recommendationBrand] = productDetails?.[0]
      ?.split("•")
      .map((detail) => detail.trim());
    posthog?.capture("navigated_to_recommendation", {
      recommendation_vendor_style: recommendationStyle,
      recommendation_dgi_style: dgiStyleSku,
      recommendation_brand: recommendationBrand,
      recommendation_color: firstColor?.name,
      recommendation_type: "similar",
      original_vendor_style: originalVendorStyle,
      original_dgi_style: originalDGIStyleSKU,
      original_brand: originalProductBrand,
      original_color: originalProductColor,
      source: location.pathname,
    });
    navigate(buildProductUrl(dgiStyleSku, firstColor?.name))
  };

  if (loadingProduct) {
    return (
      <Skeleton
        className="rounded-[10px]"
        variant="rectangular"
        width={225}
        height={195}
      />
    );
  }

  const urlColor = getURLColor(firstColor);
  const imageUrl = constructImageUrl(dgiStyleSku, urlColor, 0);

  return (
    <Box
      key={dgiStyleSku}
      className="flex flex-col items-start gap-[18px] cursor-pointer p-[6px]"
      sx={{
        "&:hover": {
          ".detailText": {
            color: "rgba(0, 110, 165, 1)",
          },
        },
      }}
      onClick={navigateToProductPage}
    >
      {firstColor && displayImage ? (
        <Box
          className={`
            flex justify-center items-center
            h-[195px] w-[195px] bg-white 
            border border-solid border-grayscaleSilver rounded-[3px]`}
        >
          <img
            src={imageUrl}
            alt={null}
            onError={() => setDisplayImage(false)}
            style={{
              display: "block",
              height: "auto",
              maxHeight: "100%",
              maxWidth: "100%",
              width: "auto",
              objectFit: "contain",
            }}
          />
        </Box>
      ) : (
        <Box className="flex flex-col justify-center items-center h-[195px] w-auto">
          <Box
            className={`
              flex flex-col items-center justify-center gap-[6px]
              max-h-[175px] min-h-[175px] p-[12px] bg-grayscaleSilver rounded-[4px]`}
          >
            <Typography className="text-5xl text-grayscaleStone">
              DGI
            </Typography>
            <Typography className="text-lg text-grayscaleStone">
              Image coming soon
            </Typography>
          </Box>
        </Box>
      )}
      <Box className="flex flex-col w-[195px]">
        <AdditionalProductDetails
          productDetails={productDetails}
          vendorCodes={vendorCodes}
          additionalStyle={dgiStyleSku}
          masterColor={firstColor["name"]}
          fetchingSimilarsPrices={true}
          isRecommendation={!(dgiStyleSku === originalDGIStyleSKU)}
        />
      </Box>
    </Box>
  );
}

export default function SimilarSlideContent({
  hit,
  selectedColor,
  isSlideOpen,
}) {
  const { connectedVendorCodes } = useDataContext();
  const dispatch = useDispatch();
  const vendorCodes = getConnectedVendors(connectedVendorCodes);
  const { userCreds } = useUserStateContext();
  const { sendJsonMessage } = useWebsocketContext();
  const similarSkus = hit?.master_similar_sku || [];
  const userId = userCreds?.cognitoID;

  if (!isSlideOpen) {
    return null;
  }

  return (
    <Box
      padding="1rem"
      paddingTop="0rem"
      marginTop="0rem"
      sx={{
        height: "400px",
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
        paddingTop: "1rem",
      }}
    >
      <Box className="w-auto mt-[0rem] flex flex-wrap justify-start items-start gap-[16px]">
        {similarSkus.map((dgiStyleSku) => (
          <SimilarProductCard
            key={dgiStyleSku}
            dgiStyleSku={dgiStyleSku}
            originalVendorStyle={getStyleNumber(hit)}
            originalDGIStyleSKU={hit.id}
            originalProductColor={selectedColor?.name}
            originalProductBrand={hit.master_brand}
            dispatch={dispatch}
            vendorCodes={vendorCodes}
            userId={userId}
            sendJsonMessage={sendJsonMessage}
          />
        ))}
      </Box>
    </Box>
  );
}
