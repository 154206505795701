import React, { useState, useRef } from "react";

import styles from "./styles.module.scss";
import SideCheckoutItemEntry from "./SideCheckoutItemEntry";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Slide, Stack, Box, Typography } from "@mui/material";
import { capitalizeWords } from "utilities/formatStringCasing";
import {
  CheckoutItemText,
  MoreCartDetailsText,
} from "custom/text/shopping_page/StandardSideCartTexts";
import { useNavigate } from "react-router-dom";
import { getURLColor, constructImageUrl } from "utilities/imageUrlUtils";
import { buildProductUrl } from "utilities/navigation";

const SideCheckoutItem = ({ styleItem, hidePrices, isCartOpen }) => {
  const [showCheckoutItems, setShowCheckoutItems] = useState(false);
  const [imgAvailable, setImgAvailable] = useState(true);
  const sizedItemsSlideRef = useRef(null);
  const navigate = useNavigate();

  let totalItemQuantity = 0;
  if (styleItem && styleItem.sizeEntries) {
    for (const entry of styleItem.sizeEntries) {
      totalItemQuantity += Number(entry.quantity);
    }
  }

  const showCheckoutItemsPrompt = showCheckoutItems ? (
    <>
      <MoreCartDetailsText content="See less" />
      <KeyboardArrowUpIcon className={styles.cartItemsPromptIcon} />
    </>
  ) : (
    <>
      <MoreCartDetailsText content="See more" />
      <KeyboardArrowDownIcon className={styles.cartItemsPromptIcon} />
    </>
  );

  const navigateToProductPage = (event) => {
    const dgiStyleSKU = styleItem.dgiStyleSKU;
    const colorName = styleItem.masterColor;
    navigate(buildProductUrl(dgiStyleSKU, colorName));
    event.preventDefault();
  };

  const imgUrlColor = getURLColor({ name: styleItem.masterColor });
  const imageUrl = constructImageUrl(styleItem?.dgiStyleSKU, imgUrlColor, 0);

  let renderImage = null;
  if (isCartOpen) {
    if (imgAvailable) {
      renderImage = (
        <img
          src={imageUrl}
          onError={() => setImgAvailable(false)}
          alt="Product image coming soon"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      );
    }
  }

  return (
    <div className={styles.sideCheckoutItem}>
      <div className="flex gap-[1.33rem] items-center pb-[1rem]">
        <Box
          sx={{
            display: "block",
            height: "auto",
            maxHeight: "100%",
            margin: 0,
            maxWidth: "55px",
            width: "auto",
          }}
        >
          {renderImage}
        </Box>
        <div className="flex flex-col gap-[8px]">
          <Box
            sx={{
              "&:hover": {
                "& *": {
                  color: "rgba(0, 110, 165, 1)", // blueblue
                },
                cursor: "pointer",
              },
            }}
            onClick={navigateToProductPage}
          >
            <Typography className="text-[16px] font-bold">
              {styleItem.style}
            </Typography>
            <CheckoutItemText content={styleItem.description} />
          </Box>
          <div>
            <CheckoutItemText
              infoTag="Color: "
              content={capitalizeWords(styleItem?.masterColor, [" "])}
            />
          </div>
          <div>
            <CheckoutItemText
              infoTag="Warehouse: "
              content={capitalizeWords(styleItem?.warehouse, [" "])}
            />
          </div>
        </div>
      </div>
      <div
        onClick={() => setShowCheckoutItems(!showCheckoutItems)}
        style={{ cursor: "pointer" }}
      >
        <Stack direction="row" justifyContent="space-between">
          <div className={styles.totalQuantity}>
            <MoreCartDetailsText
              content={`Total Qty: ${totalItemQuantity}`}
              style={{ fontWeight: 600 }}
            />
          </div>
          <div className={styles.showCartItemsPrompt}>
            {showCheckoutItemsPrompt}
          </div>
        </Stack>
      </div>

      <div
        className={styles.slideSizedItemsWrapper}
        data-open={showCheckoutItems}
        ref={sizedItemsSlideRef}
      >
        <Slide
          in={showCheckoutItems}
          direction="down"
          className={styles.slideSizedItems}
          container={sizedItemsSlideRef.current}
          anchor="up"
        >
          <div className={styles.sideSizedItems}>
            {styleItem.sizeEntries.map((sizeEntry, index) => (
              <SideCheckoutItemEntry
                key={`${sizeEntry.size}_${index}`}
                entryData={sizeEntry}
                hidePrices={hidePrices}
              />
            ))}
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default SideCheckoutItem;
