import { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useParams, useSearchParams } from "react-router-dom";
import { getURLColor, getValidImageUrls } from "utilities/imageUrlUtils";
import ProductLongDescription from "./ProductLongDescription";
import ProductCategories from "./ProductCategories";
import ProductHeaderDetails from "./ProductHeaderDetails";
import DirectLogo from "items/DirectLogo";

const ImageList = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "50px", // Set to match thumbnail width
  marginRight: "10px",
});

const ThumbnailImage = styled("img", {
  shouldForwardProp: (prop) => prop !== "isSelected",
})(({ isSelected }) => ({
  width: "100%",
  height: "auto",
  marginBottom: "8px",
  cursor: "pointer",
  border: isSelected
    ? "2px solid rgb(2 129 192)" // lighterblueblue
    : "2px solid lightgray",
  borderRadius: "8px",
  "&:hover": {
    opacity: 0.8,
  },
}));

const Arrow = styled("div")({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  padding: "0 10px",
  "&:hover": {
    opacity: 0.8,
  },
  height: "100%",
});

const Placeholder = ({ isScreenBelow440px }) => (
  <Box
    sx={{
      flexGrow: 1,
      height: "400px",
      bgcolor: "#F6F6F6",
      width: isScreenBelow440px ? "300px" : "336px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "#d2d6da",
      borderRadius: 1,
      p: 1.5,
    }}
  >
    <Typography variant="h1" sx={{ color: "inherit", marginBottom: 0 }}>
      DGI
    </Typography>
    <Typography variant="body1" sx={{ color: "inherit" }}>
      Image coming soon
    </Typography>
  </Box>
);

const RenderDirectLogo = ({ isDirectProduct }) => {
  if (!isDirectProduct) {
    return null;
  }
  return (
    <Box className="w-full flex justify-start ml-[3rem] mt-[0.5rem]">
      <DirectLogo onProductPage={true} />
    </Box>
  );
};

const ProductDetailsSection = ({
  selectedVendorCode,
  vendorProduct,
  smExclusive,
  isLGOrAbove,
  isDirectProduct,
}) => {
  const { dgiStyle } = useParams();
  const [searchParams] = useSearchParams();
  const colorQuery = searchParams.get("color");
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);

  const theme = useTheme();
  const isScreenAbove550px = useMediaQuery(theme.breakpoints.up(550));
  const isScreenBelow440px = useMediaQuery(theme.breakpoints.down(440));

  useEffect(() => {
    (async () => {
      if (smExclusive) {
        const images = vendorProduct?.data?.images || [];
        setImageUrls(images);
      } else {
        const searchColor = { name: colorQuery };
        const imgColor = getURLColor(searchColor);
        const newImageUrls = getValidImageUrls(dgiStyle, imgColor);
        setImageUrls(newImageUrls);
      }
    })();
  }, [dgiStyle, colorQuery, JSON.stringify(vendorProduct?.data?.images)]);

  const goToNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex(
      (prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length
    );
  };

  const onImgError = (url) => {
    setImageUrls((prevImageUrls) => {
      const urlIndex = prevImageUrls.findIndex((prevUrl) => prevUrl === url);
      const newImageUrls = prevImageUrls
        .slice(0, urlIndex)
        .concat(prevImageUrls.slice(urlIndex + 1));
      return newImageUrls;
    });
  };

  const hasImages = imageUrls.length > 0;

  return (
    <Box display="flex" flexDirection="column" alignItems="center" className="w-full">
      {!isLGOrAbove && <RenderDirectLogo isDirectProduct={isDirectProduct} />}
      <ProductHeaderDetails />
      <Box display="flex" alignItems="start" minHeight="350px">
        {isScreenAbove550px && hasImages && (
          <ImageList>
            {imageUrls.map((url, index) => (
              <ThumbnailImage
                key={index}
                src={url}
                onError={() => onImgError(url)}
                onClick={() => setSelectedImageIndex(index)}
                isSelected={selectedImageIndex === index}
              />
            ))}
          </ImageList>
        )}
        <Box className="flex flex-col items-center gap-[24px] h-full">
          <Box className="flex items-center h-full">
            <Arrow onClick={goToPreviousImage}>
              <ArrowBackIosNewIcon />
            </Arrow>
            <div>
              {hasImages ? (
                <img
                  src={imageUrls[selectedImageIndex]}
                  alt="Selected Product"
                  style={{
                    width: isScreenBelow440px ? "300px" : "360px",
                    height: "auto",
                  }}
                />
              ) : (
                <Placeholder isScreenBelow440px={isScreenBelow440px} />
              )}
            </div>
            <Arrow onClick={goToNextImage}>
              <ArrowForwardIosIcon />
            </Arrow>
          </Box>
          <ProductCategories />
        </Box>
      </Box>
      <ProductLongDescription
        selectedVendorCode={selectedVendorCode}
        vendorProduct={vendorProduct}
      />
    </Box>
  );
};

export default ProductDetailsSection;
