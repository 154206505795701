import vendors from "constants/vendors";
import InventoryNumberCell from "./InventoryNumberCell";

export default function InventoryCells({
  vendorCode,
  warehouseInventoryInfo,
  loadingTableData,
  setCheckoutEntries,
  setInvalidTableCells,
  sizedPricingData,
  checkoutEntries,
  bodyRowIndex,
  setSelectedBodyRowIndex,
  triggerSaveCart,
  saveBtnAvailable,
  selectedVendorCartData,
  fetchingUpdatedData,
  dgi_style_sku,
}) {
  const getItemPrice = (sizeLabel) => {
    for (const dataPoint of sizedPricingData) {
      const [size, price] = dataPoint;
      if (size === sizeLabel) {
        return price;
      }
    }
    return null;
  };

  const shouldUpdateCell = !loadingTableData || fetchingUpdatedData;
  const canSaveToCart =
    saveBtnAvailable && Object.keys(checkoutEntries).length > 0;
  const isDirectVendor = !Object.values(vendors).find((v) => v.code === vendorCode);

  return (
    <>
      {warehouseInventoryInfo[1]["quantities"].map((inventoryEntry) => (
        <InventoryNumberCell
          vendorCode={vendorCode}
          inventoryEntry={inventoryEntry}
          itemPrice={getItemPrice(inventoryEntry["sizeLabel"])}
          warehouse={warehouseInventoryInfo[0]}
          warehouseCode={warehouseInventoryInfo[1]["warehouseCode"]}
          setCheckoutEntries={setCheckoutEntries}
          setInvalidTableCells={setInvalidTableCells}
          bodyRowIndex={bodyRowIndex}
          setSelectedBodyRowIndex={setSelectedBodyRowIndex}
          triggerSaveCart={triggerSaveCart}
          canSaveToCart={canSaveToCart}
          selectedVendorCartData={selectedVendorCartData}
          shouldUpdateCell={shouldUpdateCell}
          dgi_style_sku={dgi_style_sku}
          isDirectVendor={isDirectVendor}
        />
      ))}
    </>
  );
}
