import { useContext, createContext, useState, useEffect } from "react";

import "./App.css";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./utilities/ScrollToTop";
import RouteWrapper from "authsignin/RouteWrapper";

import { Amplify, Auth } from "aws-amplify";
import awsExports from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import { StyledEngineProvider } from "@mui/material/styles";
import { usePostHog } from "posthog-js/react";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { Configure, InstantSearch } from "react-instantsearch";
import { datadogLogs } from "@datadog/browser-logs";
import { history } from "instantsearch.js/es/lib/routers";

import lilProductClient from "./axios/lilProductClient";
import endpoints from "./axios/endpoints.js";
import vendors from "./constants/vendors";
import { fetchUserSuccess } from "features/userSlice";
import { useDispatch } from "react-redux";
import { MUI_THEME } from "custom/muiStylingOverrides";
import { ThemeProvider } from "@mui/material/styles";
import MaintenancePage from "MaintenacePage";

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

Auth.configure({
  ...awsExports,
  authenticationFlowType: "USER_PASSWORD_AUTH",
});

// Datadog configuration
// Client token is safe to show
datadogLogs.init({
  clientToken: "pub6d3a02b0106da10c398d28f1af890f8b",
  site: "datadoghq.com",
  forwardErrorsToLogs: true,
  forwardConsoleLogs: "all",
  sessionSampleRate: 100,
});

// Typesense client configuration. At the app level so that
// the search bar can live in the header, and the refinements
// + hits can live elsewhere
const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: process.env.REACT_APP_TYPESENSE_SEARCH_API_KEY2, // Be sure to use an API key that only allows search operations
    nodes: [
      {
        host: process.env.REACT_APP_TYPESENSE_HOST,
        port: "443",
        protocol: "https",
      },
    ],
    cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  query_by is required.
  additionalSearchParameters: {
    preset: "search_products_2",
  },
});
export const typesenseProductsIndexName = "products_live";
const searchClient = typesenseInstantsearchAdapter.searchClient;
export const typesenseClient = typesenseInstantsearchAdapter.typesenseClient;

const UserStateContext = createContext();
const useUserStateContext = () => useContext(UserStateContext);
export { UserStateContext, useUserStateContext };

const DataContext = createContext();
const useDataContext = () => useContext(DataContext);
export { DataContext, useDataContext };

const CheckoutContext = createContext();
const useCheckoutContext = () => useContext(CheckoutContext);
export { CheckoutContext, useCheckoutContext };

function App() {
  const [connectedVendorCodes, setConnectedVendorCodes] = useState({
    ab_data: null,
    cm_data: null,
    sm_data: null,
    ss_data: null,
    as_data: null,
    acc_data: null,
    pg_data: null,
    asc_data: null,
    sta_data: null,
    slc_data: null,
  });
  // TODO for isAuthed: define 4 states --> true, false, null (deciding), waiting (waiting for sign in event after confirm sign up)
  const [isUserAuthed, setIsUserAuthed] = useState(null);
  const [userCreds, setUserCreds] = useState({
    shopName: null,
    cognitoID: null,
    checkoutEnabled: null,
  });
  const [checkoutFormData, setCheckoutFormData] = useState({
    shippingAddress: null,
    warehouseShippingMethods: [],
    paymentMethod: null,
    poNumber: "",
  });
  const [canConfirmOrder, setCanConfirmOrder] = useState(false);
  const [lastRoutePath, setLastRoutePath] = useState(null);
  const [hasUserZip, setHasUserZip] = useState(null);
  // The goal of searchSession is to test whether our search sessions are helping users
  // 1. find the product they want
  // 2. update their cart accordingly
  // Our metric for a successful search session is whether a search converts to an add_to_cart event.
  // A session starts when a user searches via the searchbar or facet subheader.
  // A session ends when a user starts a new search or navigates to another page
  // UNLESS they are continuing their search by clicking into a product page from the shopping page.
  // Events were historically captured on user-driven actions (search or page nav), meaning sessions that lead to idle time were considered invalid.
  // PosthogSessionTracker should flush events if recording has stopped.
  // Also note that user experiences starting from the default shopping page are not valid search sessions.
  const [searchSession, setSearchSession] = useState({
    in_progress: false,
    search_term: null,
    items_added: [],
    updated_cart: false,
    prev_path: null,
    added_direct_vendor_items: false, // makes filtering on posthog easier
  });
  const posthog = usePostHog();
  const dispatch = useDispatch();
  window.Intercom("boot", {
    api_base: "https://api-iam.intercom.io",
    app_id: "iwnti10l",
  });

  useEffect(() => {
    const hasShippingAddr = checkoutFormData["shippingAddress"];
    const hasPaymentMethod = checkoutFormData["paymentMethod"];
    const hasShippingMethods =
      checkoutFormData["warehouseShippingMethods"].length > 0;
    if (hasShippingAddr && hasPaymentMethod && hasShippingMethods) {
      setCanConfirmOrder(true);
      // Need to check whether user has selected pickup options when picking up from CM's main whse
      // const cmMainWhse = checkoutFormData["warehouseShippingMethods"].find((method) => method?.["warehouseCode"] === "NC");
      // if (cmMainWhse && cmMainWhse?.["carrierCode"]?.toLowerCase() === "pick up") {
      //   if (checkoutFormData["whsePickupTime"] && checkoutFormData["whsePickupDate"]) {
      //     setCanConfirmOrder(true)
      //   } else {
      //     setCanConfirmOrder(false)
      //   }
      // } else {
      //   setCanConfirmOrder(true);
      // }
    }
  }, [checkoutFormData]);

  async function getCurrentAuthenticatedUser(retries = 0) {
    const MAX_RETRIES = 2;
    const RETRY_DELAY = 250;
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (error) {
      if (retries < MAX_RETRIES) {
        console.log(`Auth retry attempt ${retries + 1}`);
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        return await getCurrentAuthenticatedUser(retries + 1);
      } else {
        console.error("Max retries reached. Authentication failed.");
        throw error;
      }
    }
  }

  const getCognitoUser = async () => {
    try {
      const currentUser = await getCurrentAuthenticatedUser();
      if (currentUser) {
        const userShopName = currentUser["attributes"]["custom:shop_name"];
        let userID = currentUser["username"];
        if (userID.includes("@")) {
          userID = currentUser?.attributes.sub;
        }
        setIsUserAuthed(true);
        setUserCreds({
          shopName: userShopName,
          cognitoID: userID,
          checkoutEnabled: currentUser?.checkout_enabled,
        });
        posthog?.identify(userID, {
          email: currentUser["attributes"]["email"],
          shopName: userShopName,
        });
      }
    } catch (error) {
      console.error(`Failed to get Cognito user with error: ${error.message}`);
      setIsUserAuthed(false);
      setUserCreds({
        shopName: "",
        cognitoID: "",
        checkoutEnabled: false,
      });
    }
  };

  const getUserData = async () => {
    try {
      const headers = {
        "User-Identifier": userCreds.cognitoID,
      };
      const userResponse = await lilProductClient.get(
        endpoints.users(userCreds.cognitoID),
        { headers },
      );
      const distrData = userResponse.data.Distributors;
      const hasUserZip = Boolean(
        Object.keys(userResponse.data).includes("zip"),
      );
      let newConnectedCodes = {};
      const distrCodes = Object.keys(distrData);
      const vendorEntries = Object.entries(vendors);

      for (const entry of vendorEntries) {
        const [dataKey, vendorDataFields] = entry;
        const vendorCode = vendorDataFields.code;
        if (distrCodes.includes(vendorCode)) {
          newConnectedCodes[dataKey] = vendorCode;
        } else {
          newConnectedCodes[dataKey] = false;
        }
      }
      setHasUserZip(hasUserZip);
      dispatch(fetchUserSuccess({ userObj: userResponse.data }));
      newConnectedCodes = { ...newConnectedCodes };
      setConnectedVendorCodes(newConnectedCodes);
      const hasAtLeastOneConnection = Object.values(newConnectedCodes).some(
        (status) => status,
      );
      posthog?.people?.set({
        connectedVendors: hasAtLeastOneConnection,
      });
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "iwnti10l",
        name:
          userResponse?.data?.firstName + " " + userResponse?.data?.lastName, // Full name
        email: userResponse?.data?.email, // the email for your user
        user_id: userResponse?.data.UserId,
        shop_name: userResponse?.data["custom:shop_name"],
        buying_group: userResponse?.data?.buyingGroupName,
        created_at: userResponse?.data?.createdAt, // Signup date as a Unix timestamp
      });
    } catch (error) {
      console.error(`Failed to get user resp with error: ${error.message}`);
    }
  };

  useEffect(() => {
    if (isUserAuthed === null) {
      getCognitoUser();
    }
  }, [isUserAuthed]);

  useEffect(() => {
    // will evaulate to false if values are empty strings or null
    const areUserCredsValid = Boolean(userCreds.cognitoID);
    console.log(`Are all user cred fields valid: ${areUserCredsValid}`);
    if (userCreds.cognitoID) {
      getUserData();
    }
  }, [userCreds]);

  // This context basically functions as the session's app state.
  const dataContext = {
    setConnectedVendorCodes,
    connectedVendorCodes,
    searchSession,
    setSearchSession,
  };

  const userStateContext = {
    signOut: () => Auth.signOut(),
    setUserCreds: setUserCreds,
    userCreds: userCreds,
    setIsUserAuthed: setIsUserAuthed,
    isUserAuthed: isUserAuthed,
    hasUserZip,
    setHasUserZip,
  };

  const checkoutContext = {
    checkoutFormData,
    setCheckoutFormData,
    canConfirmOrder,
    setCanConfirmOrder,
    lastRoutePath,
    setLastRoutePath,
  };
  const connectedVendorSearchFilters = Object.entries(connectedVendorCodes)
    .filter((vendor) => {
      return vendor[1] && vendors[vendor[0]];
    })
    .map((vendor) => {
      return vendors[vendor[0]].search_filter;
    });
  const defaultFilters =
    connectedVendorSearchFilters.length === 0
      ? "distributors: [Alphabroder, AS Colour] || direct:=true"
      : `distributors:[${connectedVendorSearchFilters}, AS Colour] || direct:=true`;

  const createShopSpecificRouting = () => {
    // Create an instance of the original router
    // The history() function comes from a routing library and provides basic routing functionality
    const originalRouter = history();
    // Initialize a callback function (will be used later for popstate events)
    let onUpdateCallback = () => {};

    // Create a new router object that extends the original router
    const router = {
      // Spread all properties and methods from the original router
      ...originalRouter,

      // Override the read method
      // Original read(): Retrieves the current route state (e.g., path, query parameters)
      read() {
        // If the current path doesn't start with "/shop", return an empty object
        if (!window.location.pathname.startsWith("/shop")) {
          return {};
        }
        // Otherwise, use the original router's read method
        return originalRouter.read();
      },

      // Override the write method
      // Original write(routeState): Updates the current route based on the provided state
      write(routeState) {
        // If the current path doesn't start with "/shop", do nothing
        if (!window.location.pathname.startsWith("/shop")) {
          return;
        }
        // Otherwise, use the original router's write method
        originalRouter.write(routeState);
      },

      // Override the createURL method
      // Original createURL(routeState): Generates a URL string based on the provided route state
      createURL(routeState) {
        // If the current path doesn't start with "/shop", return the current URL
        if (!window.location.pathname.startsWith("/shop")) {
          return window.location.href;
        }
        // Otherwise, use the original router's createURL method
        return originalRouter.createURL(routeState);
      },

      // Override the onUpdate method
      // Original onUpdate(callback): Sets up a listener for route changes
      onUpdate(callback) {
        // Store the callback function
        onUpdateCallback = callback;
        // Add a popstate event listener
        window.addEventListener("popstate", () => {
          // If the new path starts with "/shop", call the callback
          if (window.location.pathname.startsWith("/shop")) {
            callback(router.read());
          }
        });
      },

      // Override the dispose method
      // Original dispose(): Cleans up any listeners or resources used by the router
      dispose() {
        // Call the original router's dispose method
        originalRouter.dispose();
        // Remove the popstate event listener
        window.removeEventListener("popstate", onUpdateCallback);
      },
    };

    // Return an object containing the custom router and state mapping functions
    return {
      router,
      stateMapping: {
        // Convert UI state to route state
        stateToRoute(uiState) {
          // Get the UI state for the Typesense products index
          const indexUiState = uiState[typesenseProductsIndexName] || {};
          // Return an object with relevant state properties
          return {
            query: indexUiState.query,
            page: indexUiState.page,
            refinementList: indexUiState.refinementList,
            // Add other relevant state mappings here
          };
        },
        // Convert route state to UI state
        routeToState(routeState) {
          // Return an object with the Typesense products index state
          return {
            [typesenseProductsIndexName]: {
              query: routeState.query,
              page: routeState.page,
              refinementList: routeState.refinementList,
              // Add other relevant state mappings here
            },
          };
        },
      },
    };
  };

  const customRouting = createShopSpecificRouting();

  return (
    <UserStateContext.Provider value={userStateContext}>
      <DataContext.Provider value={dataContext}>
        <CheckoutContext.Provider value={checkoutContext}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={MUI_THEME}>
              <InstantSearch
                searchClient={searchClient}
                indexName={typesenseProductsIndexName}
                routing={customRouting}
                future={{
                  preserveSharedStateOnUnmount: true,
                }}
              >
                <Configure filters={defaultFilters} />
                <BrowserRouter>
                  <ScrollToTop />
                  <Routes>
                    <Route
                      path="/terms"
                      element={
                        <RouteWrapper isAuthed={false} component="terms" />
                      }
                    />
                    <Route
                      path="/privacy"
                      element={
                        <RouteWrapper isAuthed={false} component="privacy" />
                      }
                    />
                    <Route
                      path="/product-demo"
                      element={
                        <RouteWrapper
                          isAuthed={false}
                          component="product-demo"
                        />
                      }
                    />
                    <Route
                      path="/order-history"
                      element={
                        <RouteWrapper
                          isAuthed={true}
                          component="order-history"
                        />
                      }
                    />
                    <Route
                      path="/direct/vendors"
                      element={
                        <RouteWrapper
                          isAuthed={true}
                          component="directVendors"
                        />
                      }
                    />
                    <Route
                      path="/integrations"
                      element={
                        <RouteWrapper
                          isAuthed={true}
                          component="connection_page"
                        />
                      }
                    />
                    <Route
                      path="/shop"
                      element={
                        <RouteWrapper isAuthed={true} component="dashboard" />
                      }
                    />
                    <Route
                      path="/auth"
                      element={
                        <RouteWrapper isAuthed={true} component="home" />
                      }
                    />
                    <Route
                      path="/home"
                      element={
                        <RouteWrapper isAuthed={true} component="home" />
                      }
                    />
                    <Route
                      path="/cart"
                      element={
                        <RouteWrapper isAuthed={true} component="cart" />
                      }
                    />
                    <Route
                      path="/product/:dgiStyle"
                      element={
                        <RouteWrapper isAuthed={true} component="product" />
                      }
                    />
                    <Route
                      path="/checkout/:vendorCode"
                      element={
                        <RouteWrapper isAuthed={true} component="checkout" />
                      }
                    />
                    <Route
                      path="/direct/checkout"
                      element={
                        <RouteWrapper
                          isAuthed={true}
                          component="directCheckout"
                        />
                      }
                    />
                    <Route
                      path="/confirmation/:vendorCode"
                      element={
                        <RouteWrapper
                          isAuthed={true}
                          component="confirmation"
                        />
                      }
                    />
                    <Route
                      path="/receipt/:vendorCode"
                      element={
                        <RouteWrapper
                          isAuthed={true}
                          component="receipt_page"
                        />
                      }
                    />
                    <Route
                      path="/invoices"
                      element={
                        <RouteWrapper isAuthed={true} component="invoices" />
                      }
                    />
                    <Route
                      path="/signup"
                      element={
                        <RouteWrapper isAuthed={true} component="signup_page" />
                      }
                    />
                    <Route
                      path="/our-story"
                      element={
                        <RouteWrapper isAuthed={false} component="our-story" />
                      }
                    />
                    <Route
                      path="*"
                      element={
                        isUserAuthed ? (
                          <Navigate to="/home" />
                        ) : (
                          <RouteWrapper
                            isAuthed={false}
                            component="landing-page"
                          />
                        )
                      }
                    />
                  </Routes>
                </BrowserRouter>
              </InstantSearch>
            </ThemeProvider>
          </StyledEngineProvider>
        </CheckoutContext.Provider>
      </DataContext.Provider>
    </UserStateContext.Provider>
  );
}

export default App;
