import React from "react";
import { Typography, Box, Stack, Divider } from "@mui/material";

function DirectCartHeader({ vendorCode }) {
  const CLOUDFRONT_URL = "https://d34c0c1in94n75.cloudfront.net/";
  const vendorLogo = `${CLOUDFRONT_URL}${vendorCode}_logo.png`;
  return (
    <Box
      sx={{
        width: "100%",
        p: 1,
        px: 2,
        display: "flex",
        alignItems: "center",
      }}
      className="bg-grayscaleIce"
    >
      <img 
        src={vendorLogo}
        alt={null}
        style={{
          display: 'block',
          height: '26px',
          width: 'auto'
        }}
      />
    </Box>
  );
}

const getFormattedPrices = (totalPrice, unitPrice, quantity) => {
  const stripNumber = (val) => {
    if (Number(val)) {
      return val;
    }
    return parseFloat(val.replace(/[^\d.-]/g, ""));
  };

  const totalPriceFigure = parseFloat(
    totalPrice || stripNumber(unitPrice) * stripNumber(quantity)
  ).toFixed(2);

  const formattedTotalPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(totalPriceFigure);
  const formattedUnitPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(unitPrice);
  return [formattedTotalPrice, formattedUnitPrice];
};

function OrderInfo({ unitPrice, warehouse, quantity, totalPrice }) {
  const [formattedTotalPrice, formattedUnitPrice] = getFormattedPrices(
    totalPrice,
    unitPrice,
    quantity
  );
  const showPriceBreakdown = !(unitPrice === totalPrice);
  const unitPriceBreakdown =
    showPriceBreakdown && `(${formattedUnitPrice} / Each)`;
  const itemSpend = `${formattedTotalPrice} ${unitPriceBreakdown}`;

  return (
    <Stack spacing={1}>
      <Typography>
        Total spend: <span className="font-bold">{itemSpend}</span>
      </Typography>
      <Typography>
        Quantity: <span className="font-bold">{quantity}</span>
      </Typography>
      <Typography>
        Warehouse: <span className="font-bold">{warehouse}</span>
      </Typography>
    </Stack>
  );
}

function CartItemInfo({
  itemStyle,
  description,
  imgUrl,
  unitPrice,
  warehouse,
  quantity,
  totalPrice,
}) {
  return (
    <Box className="flex items-start gap-[64px]">
      <img
        src={imgUrl}
        style={{
          display: "block",
          width: "220px",
          height: "auto",
        }}
      />
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography className="text-xl font-bold">{itemStyle}</Typography>
          <Typography className="text-lg">{description}</Typography>
        </Box>
        {/* <Divider /> */}
        <OrderInfo
          unitPrice={unitPrice}
          warehouse={warehouse}
          quantity={quantity}
          totalPrice={totalPrice}
        />
      </Stack>
    </Box>
  );
}

function DirectCartItems({ items }) {
  return (
    <Box className="flex flex-col gap-[28px]" sx={{ p: 2 }}>
      {items.map((item, index) => (
        <>
        {index > 0 && (
            <Divider />
        )}
        <CartItemInfo
          itemStyle={item.style}
          description={item.description}
          imgUrl={item.product_image_url?.[0]}
          unitPrice={item.unitPrice}
          warehouse={item.warehouse}
          quantity={item.quantity}
          totalPrice={item.totalPrice}
        />
        </>
      ))}
    </Box>
  );
}

function DirectCarts({ vendors }) {
  return (
    <div>
      {vendors?.map((v) => (
        <Box className="flex flex-col gap-[28px]">
          <DirectCartHeader vendorCode={v.directVendor} />
          <DirectCartItems items={v.items} />
        </Box>
      ))}
    </div>
  );
}

export default DirectCarts;
