import { createSelector, createSlice } from "@reduxjs/toolkit";

// This will allow us to start the fetches from the dashboard level
// (or similar), and then still trigger price fetches
// at the ProductPriceRow level if the price is not
// in loading/success state!
export const LOADING_STATES = {
  IDLE_STATUS: "idle",
  LOADING_STATUS: "loading",
  SUCCESS_STATUS: "success",
  FAILURE_STATUS: "failure",
};

const statusesThatRequireDataRequests = new Set([LOADING_STATES.IDLE_STATUS]);

const productsSlice = createSlice({
  name: "products",
  initialState: { products: {} },
  reducers: {
    fetchProductIdle(state, action) {
      const { dgi_style_sku, vendorCode, master_color, productIdentifiers } =
        action.payload;
      const key = dgi_style_sku + vendorCode + master_color;
      state.products[key] = {
        loading: LOADING_STATES.IDLE_STATUS,
        style_num: productIdentifiers?.style_num,
        error: null,
        data: null,
      };
    },
    fetchProductStartBatch(state, action) {
      const { productStyles, vendorCodes } = action.payload;
      const updatedProducts = {};
      const existingProductKeys = new Set();
      productStyles.forEach((product) => {
        if (product?.direct_vendor) {
          const key = product.dgi_style_sku + product?.direct_vendor + (product?.master_color || "");
          const productLoadingStatus = state.products?.[key]?.loading;
          const productTTL = state.products?.[key]?.ttl;
          // If the existing data is stale
          const staleData = Date.now() >= productTTL * 1000;
          if (
            statusesThatRequireDataRequests.has(productLoadingStatus) ||
            !productLoadingStatus || // all vendor + product combos will start with no loading state
            (staleData && productLoadingStatus != LOADING_STATES.LOADING_STATUS)
          ) {
            updatedProducts[key] = {
              ...state.products?.[key],
              loading: LOADING_STATES.LOADING_STATUS,
              error: null,
            };
          } else {
            existingProductKeys.add(key);
          }
        } else {
          vendorCodes.forEach((vendorCode) => {
            const key = product.dgi_style_sku + vendorCode + product.master_color;
            const productLoadingStatus = state.products?.[key]?.loading;
            const productTTL = state.products?.[key]?.ttl;
            // If the existing data is stale
            const staleData = Date.now() >= productTTL * 1000;
            if (
              statusesThatRequireDataRequests.has(productLoadingStatus) ||
              !productLoadingStatus || // all vendor + product combos will start with no loading state
              (staleData && productLoadingStatus != LOADING_STATES.LOADING_STATUS)
            ) {
              updatedProducts[key] = {
                ...state.products?.[key],
                loading: LOADING_STATES.LOADING_STATUS,
                error: null,
              };
            } else {
              existingProductKeys.add(key);
            }
          });
        }
      });
      state.products = {
        ...state.products,
        ...updatedProducts,
      };
      action.payload.existingProductKeys = Array.from(existingProductKeys);
    },
    fetchProductSuccess(state, action) {
      const {
        dgi_style_sku,
        vendorCode,
        master_color,
        productData,
        ttl,
        productIdentifiers,
      } = action.payload;
      const key = dgi_style_sku + vendorCode + (master_color || "");
      state.products[key] = {
        ...state.products[key],
        loading: LOADING_STATES.SUCCESS_STATUS,
        style_num: productIdentifiers?.style_num,
        error: null,
        data: productData,
        ttl: ttl,
      };
    },
    fetchProductFailure(state, action) {
      const {
        dgi_style_sku,
        vendorCode,
        master_color,
        errorCode,
        errorMessage,
        productIdentifiers,
      } = action.payload;
      const key = dgi_style_sku + vendorCode + master_color;
      state.products[key] = {
        ...state.products[key],
        loading: LOADING_STATES.FAILURE_STATUS,
        error: { [errorCode]: errorMessage },
        style_num: productIdentifiers?.style_num,
        data: null,
      };
    },
  },
  selectors: {
    selectVendorProduct: (state, dgi_style_sku, vendorCode, master_color) =>
      state.products?.[dgi_style_sku + vendorCode + (master_color || "")],
    selectVendorProducts: (state, dgi_style_sku, vendorCodes, master_color) => {
      const vendorProducts = {};
      for (const code of vendorCodes) {
        const key = dgi_style_sku + code + (master_color || "");
        vendorProducts[key] = state.products?.[key];
      }
      return vendorProducts;
    },
    selectVendorProductsBatch: (state, productKeys, vendorCodes) => {
      return productKeys.map(({ dgi_style_sku, master_color }) => {
        return selectVendorProducts(
          state,
          dgi_style_sku,
          vendorCodes,
          master_color
        );
      });
    },
    selectCheapestVendorPrice: (state, dgi_style_sku, master_color, vendorCodes) => {
      const allProducts = state?.products;
      let cheapestPrice = Infinity;
      for (const vendorCode of vendorCodes) {
        const vendorPrice = allProducts?.[dgi_style_sku + vendorCode + (master_color || "")]?.data?.product_price;
        if (vendorPrice < cheapestPrice) {
          cheapestPrice = vendorPrice;
        }
      }
      return cheapestPrice;
    }
  },
});


export const {
  fetchProductIdle,
  fetchProductStart,
  fetchProductStartBatch,
  fetchProductSuccess,
  fetchProductFailure,
} = productsSlice.actions;

export const {
  selectVendorProduct,
  selectVendorProducts,
  selectVendorProductsBatch,
  selectCheapestVendorPrice
} = productsSlice.selectors;

export const createSelectVendorProducts = (
  dgi_style_sku,
  vendorCodes,
  master_color
) =>
  createSelector([(state) => state.products.products], (products) => {
    const vendorProducts = {};
    for (const code of vendorCodes) {
      const key = dgi_style_sku + code + (master_color || "");
      vendorProducts[key] = products?.[key];
    }
    return vendorProducts;
  });

export default productsSlice.reducer;
