// CognitoAuth.js

import React, { useState, useEffect } from "react";
import {
  Authenticator,
  useAuthenticator,
  CheckboxField,
} from "@aws-amplify/ui-react";
import { Hub } from "aws-amplify";
import AuthedPage from "./AuthedPage";
import { useUserStateContext } from "../App";
import { useNavigate } from "react-router-dom";
import HeaderLogo from "./HeaderLogo";
import styles from "./styles.module.scss"; // Import the SCSS module

export default function CognitoAuth(props) {
  const [confirmedSignUp, setConfirmedSignUp] = useState(false);
  const [confirmedSignIn, setConfirmedSignIn] = useState(false);
  const { isUserAuthed, setIsUserAuthed, userCreds } = useUserStateContext();
  const navigate = useNavigate();
  const showHeader = props?.component === "signup_page" || props?.component === "directCheckout" ? false : true;

  Hub.listen("auth", (data) => {
    switch (data.payload.event) {
      case "confirmSignUp":
        setConfirmedSignUp(true);
        break;
      case "signIn":
        setConfirmedSignIn(true);
        setIsUserAuthed(null);
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    if (confirmedSignUp) {
      setIsUserAuthed("waiting to sign in...");
      navigate("/signup");
    }
  }, [confirmedSignUp]);

  const formFields = {
    signUp: {
      "custom:shop_name": {
        order: 1,
        isRequired: true,
        label: "Shop Name",
        placeholder: "Enter your Shop Name",
      },
      email: {
        order: 2,
      },
      phone_number: {
        order: 3,
      },
      password: {
        order: 4,
      },
      confirm_password: {
        order: 5,
      },
    },
  };

  const termsMessage = (
    <div>
      <span>I accept the </span>
      <a href="/terms" target="_blank" rel="noopener noreferrer">
        Terms of Service
      </a>
    </div>
  );

  return (
    <div
      className={styles.appRoot}
      data-profile={isUserAuthed}
      style={{ marginBottom: '10rem' }} // Add this line
    >
      <Authenticator
        formFields={formFields}
        loginMechanisms={["email"]}
        components={{
          Header: HeaderLogo,
          SignUp: {
            FormFields() {
              const { validationErrors } = useAuthenticator();

              return (
                <>
                  <Authenticator.SignUp.FormFields />
                  <CheckboxField
                    hasError={!!validationErrors.acknowledgement}
                    name="acknowledgement"
                    value="yes"
                    label={termsMessage}
                  />
                </>
              );
            },
          },
        }}
        services={{
          async validateCustomSignUp(formData) {
            if (!formData.acknowledgement) {
              return {
                acknowledgement: "You must agree to the Terms of Service",
              };
            }
          },
        }}
      >
        {userCreds?.cognitoID && (
          <AuthedPage
            component={props?.component}
            windowWidth={props?.windowWidth}
            showHeader={showHeader}
          />
        )}
      </Authenticator>
    </div>
  );
}
