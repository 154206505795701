import React from "react";
import Box from "@mui/material/Box";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { usePostHog } from "posthog-js/react";
import getStyleNumber from "utilities/getStyleNumber";
import { useLocation } from "react-router-dom";
import DirectLogo from "./DirectLogo";

const RenderDirectLogo = ({ isDirectProduct }) => {
  if (!isDirectProduct) {
    return null;
  }
  return (
    <Box className="mb-[-4px] max-[1130px]:mr-0">
      <DirectLogo />
    </Box>
  );
};

export default function UtilityBar({
  hit,
  onSimilarClick,
  selectedColor,
  openSimilarSlide,
  direct,
}) {
  const posthog = usePostHog();
  const location = useLocation();

  function formatNumberWithCommas(number) {
    let numStr = String(number);

    let parts = numStr.split(".");
    let integerPart = parts[0];
    let decimalPart = parts.length > 1 ? "." + parts[1] : "";

    let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedInteger + decimalPart;
  }

  const similarSkuCount = hit?.master_similar_sku?.length || 0;

  return (
    <Box
      sx={{
        flexGrow: 1,
        justifyContent: "flex-end",
        alignItems: "flex-end",
        paddingBottom: "6px",
        width: "100%",
        zIndex: 2,
        position: "relative",
        gap: "2rem",
        display: "flex",
        alignItems: "flex-end",
        bottom: 0,
        right: 10,
      }}
    >
      <RenderDirectLogo isDirectProduct={direct} />
      {similarSkuCount > 0 && (
        <Box
          sx={{
            display: {
              xs: "none",
              md: "flex",
              "@media (max-width:1130px)": { display: "none" },
            },
            alignItems: "center",
            position: "relative",
            mb: "-2px",
            cursor: "pointer",
            zIndex: 10,
            "&:hover": {
              textDecoration: "underline",
              TextDecorationColor: "#676F78",
            },
          }}
          onClick={(e) => {
            // track clicks for most basic analytic on button
            if (!openSimilarSlide) {
              posthog?.capture("fetch_similars_prices", {
                original_vendor_style: getStyleNumber(hit),
                original_dgi_style: hit.id,
                original_brand: hit.master_brand,
                original_color: selectedColor?.name,
                source: location.pathname,
              });
            }
            onSimilarClick(e);
          }}
        >
          <SwapHorizIcon
            sx={{ color: "#676F78", fontSize: 22, verticalAlign: "bottom" }}
          />
          <Typography
            sx={{
              color: "#676F78",
              lineHeight: 1,
              verticalAlign: "bottom",
              fontSize: "14px",
              fontWeight: 400,
              mb: "-3px",
            }}
          >
            Similar
          </Typography>
          <Box
            sx={{
              position: "absolute",
              top: "-5px",
              left: "96%",
              color: "#fff",
              height: "14px",
              textAlign: "center",
              minWidth: "14px",
              fontSize: "11px",
              lineHeight: "14px",
              backgroundColor: "rgb(125, 125, 221)",
              borderRadius: "8px",
              padding: "0 3px",
              fontWeight: "500",
              verticalAlign: "top",
            }}
          >
            {similarSkuCount}
          </Box>
        </Box>
      )}

      {hit?.order_count > 0 && (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                background: "#f9f9f9",
                opacity: "0.6",
                fontSize: "14px",
                color: "#111",
                marginTop: "6px !important",
                boxShadow:
                  "0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07)",
                border: "1px solid darkgray",
                maxWidth: "440px",
              },
            },
            arrow: {
              sx: {
                color: "#f9f9f9",
                borderBottom: "none",
                "::before": {
                  border: "1px solid darkgray",
                },
              },
            },
          }}
          title={
            <Typography>
              This style has been ordered{" "}
              <span style={{ fontWeight: "bold" }}>
                {formatNumberWithCommas(hit?.order_count)}
              </span>{" "}
              times by DGI users
            </Typography>
          }
          arrow
        >
          <Box
            sx={{
              display: {
                xs: "none",
                md: "flex",
                "@media (max-width:1130px)": { display: "none" },
              },
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <LeaderboardIcon
              sx={{
                color: "#57c559",
                mr: 0.5,
                verticalAlign: "bottom",
                fontSize: 15,
              }}
            />
            <Typography
              sx={{
                color: "#57c559",
                lineHeight: 1,
                verticalAlign: "bottom",
                fontSize: "14px",
              }}
            >
              {formatNumberWithCommas(hit?.order_count)}
            </Typography>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
}
