import React from "react";
import { Box } from "@mui/material";

function DirectLogo({ onProductPage=false }) {
  const blueBaseDirectLogo =
    "https://d34c0c1in94n75.cloudfront.net/direct_logo_blue_base_orange_arrow.png";
  return (
    <Box>
      <img
        src={blueBaseDirectLogo}
        alt={null}
        className={`${onProductPage ? "w-[115px]" : "w-[65px]"} max-h-auto`}
      />
    </Box>
  );
}

export default DirectLogo;
