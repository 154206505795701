import { useEffect, useState, useContext, createContext } from "react";
import ProductPageContent from "./ProductPageContent";
import SideCart from "pages/cart/SideCart";
import AdditionalStyles from "./AdditionalStyles";
import { useParams, useSearchParams } from "react-router-dom";
import { useUserStateContext, useDataContext } from "App";
import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/material";

import lilProductClient from "../../axios/lilProductClient";
import endpoints from "../../axios/endpoints";
import { vendorPayloadFields } from "features/useSocketPayloadSender";
import vendors from "constants/vendors";
import { suggestVendorConnections } from "constants/vendors";
import { useSelector, useDispatch } from "react-redux";
import { fetchStyleSuccess, selectStyle } from "features/styleSlice";

const ProductContext = createContext();
const useProductContext = () => useContext(ProductContext);
export { ProductContext, useProductContext };

const parseLongDescription = (rawLongDescription) => {
  if (!rawLongDescription) return "";

  if (rawLongDescription.match(/<\/?([a-z][a-z0-9]*)\b[^>]*>?/gi)) {
    return rawLongDescription
      .replace(/<li>/g, "")
      .replace(/<\/li>|<li>/g, ";")
      .replace(/&quot;/g, '"')
      .replace(/&amp;/g, "&")
      .replace(/&nbsp;/g, " ")
      .replace(/<[^>]*>/g, "")
      .split(";").filter((point) => point);
      
  } else {
    return rawLongDescription.split(";").filter((point) => point);
  }
};

export const processProductData = (data) => {
  const parsedData = { ...data };

  // TODO: REMOVE || and ? : logic
  parsedData.dgi_style_sku = data.dgi_style_sku;
  parsedData.id = data.dgi_style_sku;
  parsedData.colors = data?.colors
    ? JSON.parse(data.colors.replace(/'/g, '"'))
    : [];
  parsedData.categories = data["master_category_tags"]
    ? JSON.parse(
        data["master_category_tags"]
          .replace(/'/g, '"')
          .replace(/\\/g, "\\\\")
          .replace(/"s\"/g, "'s\""),
      )
    : [];
  parsedData.styleNumber = data["master_style"] || data["style"];
  parsedData.brand = data["master_brand"] || data["brand"];
  parsedData.shortDescription =
    data["master_short_description"] || data["short_description"];
  parsedData.longDescriptionPoints = parseLongDescription(data?.master_long_description);
  parsedData.similarStyles = data.master_similar_sku
    ? JSON.parse(data?.master_similar_sku?.replace(/'/g, '"')).map((style) =>
        style?.toString(),
      )
    : [];
  parsedData.companionStyles = data.master_companion_sku
    ? JSON.parse(data?.master_companion_sku?.replace(/'/g, '"'))
        .filter((style) => style !== data?.dgi_style_sku)
        .map((style) => style?.toString())
    : [];

  parsedData.vendorFields = {};
  let payloadFields = Object.values(vendorPayloadFields)?.flat();
  for (const payloadField of payloadFields) {
    if (data?.[payloadField]) {
      parsedData.vendorFields[payloadField] = data?.[payloadField];
    }
  }

  return parsedData;
};

const ProductPage = ({ sendJsonMessage }) => {
  const { userCreds } = useUserStateContext();
  const { dgiStyle } = useParams();
  const [searchParams] = useSearchParams();
  const colorQuery = searchParams.get("color");
  const [loadingProductData, setLoadingProductData] = useState(true);
  const [failedFetch, setFailedFetch] = useState(false);
  const { connectedVendorCodes } = useDataContext();

  // Color selector state vars
  const [productColors, setProductColors] = useState([]);

  // Vendor specific state vars
  const [vendorFields, setVendorFields] = useState(null);

  // Category state var
  const [categories, setCategories] = useState([]);

  // Product header state vars
  const [styleNumber, setStyleNumber] = useState("");
  const [brand, setBrand] = useState("");
  const [shortDescription, setShortDescription] = useState("");

  // Long Description
  const [longDescriptionPoints, setLongDescriptionPoints] = useState([]);

  // Similars and Companions
  const [similarStyles, setSimilarStyles] = useState([]);
  const [companionStyles, setCompanionStyles] = useState([]);
  const dispatch = useDispatch();

  // Select the style from the Redux store
  const productData = useSelector((state) => selectStyle(state, dgiStyle));

  useEffect(() => {
    (async () => {
      try {
        let parsedData;
        if (productData) {
          // If data is in Redux store, use it
          parsedData = productData;
          setLoadingProductData(false);
        } else {
          // If not in Redux store, fetch from API
          setLoadingProductData(true);
          const headers = {
            "User-Identifier": userCreds.cognitoID,
          };
          const pageResp = await lilProductClient.get(
            endpoints.getProductInfo(dgiStyle),
            { headers },
          );
          parsedData = processProductData(pageResp.data);

          // Dispatch to Redux
          dispatch(fetchStyleSuccess({ styleObj: parsedData }));
        }

        // Update local state
        setProductColors(parsedData.colors);
        const newVendorFields = parsedData.direct
          ? parsedData
          : {
              id: parsedData.dgi_style_sku || parsedData.id,
              ...parsedData.vendorFields,
            };
        setVendorFields(newVendorFields);
        setCategories(parsedData.categories);
        setStyleNumber(parsedData.styleNumber);
        setBrand(parsedData.brand);
        setShortDescription(parsedData.shortDescription);
        setLongDescriptionPoints(parsedData.longDescriptionPoints);
        setSimilarStyles(parsedData.similarStyles);
        setCompanionStyles(parsedData.companionStyles);

        setLoadingProductData(false);
      } catch (error) {
        console.error(`Failed to get product data for page: ${error.message}`);
        setFailedFetch(true);
        setLoadingProductData(false);
      }
    })();
  }, [userCreds, dgiStyle, dispatch, productData]);

  let colorObj = null;
  let colorNames = [];
  if (productColors.length > 0) {
    if (colorQuery) {
      colorObj = productColors.find((c) => c.name === colorQuery);
      colorNames = productColors.map((c) => c.name);
    }
  }

  const productContext = {
    colorObj,
    colorNames,
    productColors,
    vendorFields,
    styleNumber,
    brand,
    shortDescription,
    categories,
    longDescriptionPoints,
    similarStyles,
    companionStyles,
    loadingProductData,
    setLoadingProductData,
  };

  const isWidthAbove1615px = useMediaQuery("(min-width:1615px)");

  let unsortedDisplayVendors = [];
  // for prompting users to connect at least one relevant vendor
  let unconnectedVendorCodes = [];
  let displaySuggestedVendors = [];
  if (productData) {
    if (productData.direct) {
      unsortedDisplayVendors = [{ code: productData.direct_vendor }];
    } else if (vendorFields) {
      unsortedDisplayVendors = Object.entries(vendors)
        .filter(
          ([dataKey, { style_field }]) =>
            connectedVendorCodes[dataKey] && vendorFields[style_field],
        )
        .map((v) => v[1]);

      unconnectedVendorCodes = Object.entries(vendors)
        .map(([, { style_field, code }]) =>
          vendorFields[style_field] ? code : null,
        )
        .filter(Boolean);

      displaySuggestedVendors = Object.entries(vendors)
        .filter(
          ([dataKey, { code, style_field }]) =>
            suggestVendorConnections.has(code) &&
            !connectedVendorCodes[dataKey] &&
            vendorFields[style_field],
        )
        .map((v) => v[1]);
    }
  }
  const vendorCodes = unsortedDisplayVendors.map((v) => v["code"]);

  return (
    <ProductContext.Provider value={productContext}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem",
            gap: "1rem",
          }}
        >
          <ProductPageContent
            sendJsonMessage={sendJsonMessage}
            unsortedDisplayVendors={unsortedDisplayVendors}
            displaySuggestedVendors={displaySuggestedVendors}
            vendorCodes={vendorCodes}
            unconnectedVendorCodes={unconnectedVendorCodes}
            styleData={productData}
          />
          {isWidthAbove1615px && <SideCart />}
        </div>
        <Box marginTop="40px" width="100%">
          <AdditionalStyles
            caseType="similar"
            sectionTitle="Similars"
            additionalStyles={similarStyles}
            sendJsonMessage={sendJsonMessage}
            styleNumber={styleNumber}
            dgiStyleSKU={dgiStyle}
            productBrand={brand}
            productColor={colorQuery}
          />
          <AdditionalStyles
            caseType="companion"
            sectionTitle="Companions"
            additionalStyles={companionStyles}
            sendJsonMessage={sendJsonMessage}
            styleNumber={styleNumber}
            dgiStyleSKU={dgiStyle}
            productBrand={brand}
            productColor={colorQuery}
          />
        </Box>
      </div>
    </ProductContext.Provider>
  );
};

export default ProductPage;
